import React, { Component, useEffect, useState, useMemo, useRef } from 'react';
import axios from 'axios';

import { downloadFile, getFileNameFromPath, uuidv4 } from '../../App.Events';
import { withContext } from './../../Persistant/AppContext';

import VideoPoster from '../tpl/ListingHelpers/tpl_video_poster';
import VideoFilter from '../tpl/ListingHelpers/tpl_video_filter';
import VideoPlayerContainer from '../tpl/ListingHelpers/tpl_video_player_container';
import VideoInfo from '../tpl/ListingHelpers/tpl_video_info';
import Spiff from '../../Assets/Images/spiffing-brit.png';
import Spooge from '../../Assets/Images/spooging-brit.png';
import PlayerBG from '../../Assets/Images/no-player-bg.jpg';
import xmlrpc from 'xmlrpc';

import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

axios.defaults.withCredentials = true

export const GET_DUPLICATE_MOVIES = gql`
query getMovieDuplicates($movie: MovieInput!, $first: Int, $skip: Int) {
	movieDuplicatesByMovie(movie: $movie, first: $first, skip: $skip) {
	  part_key
	  vid_resolution
	  vid_width
	  vid_title
	  vid_height
	  vid_thumb
	  vid_art
	  vid_year
	  vid_audioCodec
	  vid_videoCodec
	  vid_duration
	  part_file
	  part_size
	  library {
		lib_title
		id
		resource {
		  id
		  presence
		  name
		  accessToken
		  Connection {
			uri
			address
		  }
		}
	  }
	}
  }  
`

export const GET_MOVIES = gql`
  query GetMovies ($query: String!, $first: Int, $skip: Int) {
	movieGroup(query: $query, first: $first, skip: $skip) {
      vid_title
      count
	  vid_width
	  vid_height
      movies {
	    part_key
		vid_resolution
		vid_width
		vid_title
		vid_height
		vid_thumb
		vid_art
		vid_year
		vid_audioCodec
		vid_videoCodec
		vid_duration
		part_file
		part_size
		library {
		  lib_title
		  id
		  resource {
			id
			presence
			name
		    accessToken
		    Connection {
			  uri
			  address
		    }
	      }
	    }
      }
    }
  }
`;

export const GET_MOVIE_THUMBS = gql`
  query GetMovieThumbs ($query: String!, $first: Int, $skip: Int, $coverLimit: Int) {
	movieGroupMany(q: $query, skip: $skip, limit: $first) {
	  count
	  score
	  _id {
		title
	  }
	  movie(limit: $coverLimit) {
		thumb
		art
		library {
		  language
		}
		resource {
		  accessToken
		  connection {
			uri
			local
			relay
		  }
		}
	  }
	}
  }
`;


const MessageScreen = () => (
	<div className='vid-list-msg text-center'>Welcome to PlexWeb</div>
)

const LoadingScreen = ({safeSearch}) => (
	safeSearch ?
		<div className='vid-list-msg text-center'>
			I'm still looking aniki! uwu...
			<div className='loader' />
		</div>
		:
		<div className='vid-list-msg text-center'>
			HOLD YER HORSES CUNT IM LOOKING...
			<div className='loader' />
		</div>
)

const VideoPosters = ({query, limit = 30, page, nsfw_list, onLoaded, safeSearch, renderItem}) => {
	//AutoQuote searches.
	const quotedQuery = (query.match(/"/) ?
		query : query.split(' ').map(function(item) {
			return item.match(/^-/) ? item : `"${item}"`;
		}).join(' ')
	);

	const { data, loading } = useQuery(GET_MOVIES, {
		variables: {
			query: quotedQuery,
			coverLimit: 2,
			first: limit,
			skip: (((page - 1) || 0) * limit),
		},
	})

	const pageRef = useRef({query,page});

	const [videoPoster, setVideoPoster] = useState({});

	useEffect(() => {
		const {lastQuery, lastPage} = pageRef.current
		const movieGroup = (data || {}).movieGroup || undefined;
		if (!videoPoster) {
			if (movieGroup)
				setVideoPoster({
					[query]: {
						[page]: movieGroup
					}
				})
			return;
		}
		if (lastQuery != query || page != lastPage) {
			pageRef.current = {query, page};
			const cachedQuery = videoPoster[query] || {};
			setVideoPoster({
				...videoPoster,
				[query] : {
					...cachedQuery,
					[page]: movieGroup || undefined
				}
			 })
			 if (onLoaded) {
				 onLoaded();
			 }
		}
	}, [data])

	const queryList = videoPoster[query] || {};

	const videoPosterList = Object.keys(queryList).sort().reduce(
		(accumulator, key) => ([
			...accumulator,
			...(queryList[key] || [])
		]), [],
	)

	if (!query)
		return null;
	
	return (!videoPosterList.length && loading) ? <LoadingScreen safeSearch={safeSearch}/> : (
		<div className='video-list-container'>
			<div className='video-list' id='video-list'>
				{videoPosterList.map(item => renderItem(item, nsfw_list))}
				<div className='vis-indicator' />
			</div>
		</div>
	);
}

const Overlay = ({type, contextSource, callbackInfoScreenPlay}) => {
	const firstSource = (
		contextSource && contextSource.sources
		&& contextSource.sources.length && contextSource.sources[0]
	) || undefined;

	const {
		vid_title,
		vid_duration,
		vid_audioCodec,
		vid_height,
		vid_videoCodec,
		part_size,
		vid_width
	} = firstSource || {};

	const downloadURL = useRef();

	const downloadElementRef = useRef(null);


	const [sources, setSources] = useState([]);

	const { refetch, loading } = useQuery(GET_DUPLICATE_MOVIES, {
		variables: {
			movie: {
				vid_title,
				vid_duration,
				vid_audioCodec,
				vid_height,
				vid_videoCodec,
				part_size,
				vid_width
			},
			first: 10000
		},
		skip: true
	})

	useEffect(
		() => {
			if (type === 'player' && contextSource)
			{
				setSources(null)
				refetch().then(
					({data}) => setSources(data.movieDuplicatesByMovie.filter( x=>
						x.library && x.library.resource && x.library.resource.Connection &&
						x.library.resource.Connection.filter(c=> c && c.local != '1').length
					))
				);
			}
			if (type === 'ariaList' && contextSource?.newTitle) {
				console.log('contextSource', contextSource);
				setSources(null)
				refetch().then(
					({data}) => {
						const sources = data.movieDuplicatesByMovie.filter( x=>
						x.library && x.library.resource && x.library.resource.Connection &&
						x.library.resource.Connection.filter(c=> c && c.local != '1').length)
						console.log('ariaList', sources);
						//Release prior Object if exists
						if (downloadURL.current) {
							URL.revokeObjectURL(downloadURL.current);
						}
						// Get Download link
						let download = document.querySelector('.downloadListOndemand');
						//Generate aria recongnizable format for text downloads
						let out = '';
						for (let key in sources) {
							let fileLocation =
								sources[key].library.resource.Connection[0].uri +
								sources[key].part_key +
								'?X-Plex-Token=' +
								sources[key].library.resource.accessToken +
								'&download=1\t';
							out += fileLocation;
						}
						console.log(out);
						//Create blob
						let file = new Blob([out], { type: 'text/plain;charset=utf-8' });
						//Generate new blob URL
						downloadURL.current = URL.createObjectURL(file);
						//Set addressable version of the blob to the download link
						console.log('contextSource', contextSource);
						const title = 'aria-' + contextSource.newTitle + '.plexaria'
						download.setAttribute('download', title);
						download.setAttribute('href', downloadURL.current);
						downloadElementRef.current.click();
						document.dispatchEvent(new CustomEvent('closeItemOverlay'));
					}
				)
			}
			if (type === 'ariaFetchLoader' && contextSource)
			{
				setSources(null)
				refetch().then(
					({data}) => {
						const sources = data.movieDuplicatesByMovie.filter( x=>
						x.library && x.library.resource && x.library.resource.Connection &&
						x.library.resource.Connection.filter(c=> c && c.local != '1').length)
						console.log('aria-rpc');
						if (localStorage.getItem('aria-settings')) {
							try {
								let ariaSettings = JSON.parse(localStorage.getItem('aria-settings'));
								let q = new URL(ariaSettings.uri);
				
								let client = xmlrpc.createClient({ host: q.hostname, port: q.port, path: q.pathname });
								let urls = [];
								for (let key in sources) {
									urls.push(		
										sources[key].library.resource.Connection[0].uri +
										sources[key].part_key +
										'?X-Plex-Token=' +
										sources[key].library.resource.accessToken +
										'&download=1'
									);
								}
				
								client.methodCall(
									'aria2.addUri',
									[
										`token:${ariaSettings.secret}`,
										urls,
										{ out: `${getFileNameFromPath(sources[0].part_file)[0]}` },
									],
									function(error, value) {
										if (error) alert(error);
										console.log(value);
									},
								);
								document.dispatchEvent(new CustomEvent('closeItemOverlay'));
							} catch (err) {
								alert("Issue connecting to your Aria RPC Server, please make sure it's running");
								document.dispatchEvent(new CustomEvent('closeItemOverlay'));
							}
						} else {
							alert('Please go setup your aria settings in the settings menu');
							document.dispatchEvent(new CustomEvent('closeItemOverlay'));
						}
					}
				);
			}
		},
		[contextSource],
	)

	switch (type) {
		case 'player':
			return <VideoPlayerContainer contextSource={{
				...contextSource,
				sources: (!loading && sources) || contextSource.sources
			}} />;
		case 'info':
			return (
				<div id='vid-info-container' className='vid-info-container'>
					<VideoInfo
						sources={(!loading && sources) || contextSource.sources}
						parentCallback={callbackInfoScreenPlay}
					/>
				</div>
			);
		default:
			return <div
				className='loader'
			><a
				hidden={true}
				className='downloadListOndemand'
				download='aria-.txt'
				ref={downloadElementRef}
			/></div>;
	}
}

class MovieListings extends Component {
	downloadURL = '';

	constructor(props) {
		super(props);
		this.apiEndpoint = '/api';
		this.state = this.getInitialState();
		this.getNSFWList()
		if (this.props.debug) console.log(this.props);
	}

	getNSFWList = async () => {
		const endpoint = `${this.apiEndpoint}/nsfw/`
		this.setState({ nsfw_list: await axios(endpoint)})
	}

	getInitialState = () => {
		const initialState = {
			isLoading: true,
			query: null,
			page: 0,
			items: [],
			contextSource: null,
			infoSource: null,
			firstload: true,
			showLoader: true,
			user: JSON.parse(localStorage.getItem('user')),
			allowViewportMonitor: true,
		};
		return initialState;
	};

	resetState = () => {
		this.setState(this.getInitialState());
	};

	componentDidMount() {
		this.setState({
			isLoading: false,
			items: [],
		});
		document.addEventListener(
			'IsInViewport',
			e => {
				this.handleIsInViewPort(e);
			},
			false,
		);
	}

	handleIsInViewPort = e => {
		setTimeout(async () => {
			if (this.state.allowViewportMonitor) {
				this.setState({ allowViewportMonitor: false });
				// Detect current page position
				let visCount = document.querySelectorAll('.vid-item').length;
				// Load more vid items
				await this.showMore();
				// move screen back after re-render
				document.querySelectorAll('.vid-item')[visCount].scrollIntoView();
			}
		}, 2000);
	};

	handleSubmit = e => {
		window.stop();
		e.preventDefault();
		this.resetState();
		this.setState(
			{
				isLoading: false,
				firstload: false,
				items: [],
				page: 1,
				query: document.querySelector('.searchbar').value,
				showLoader: false,
			}
		);
	};

	callbackChildSources = childData => {
		this.setState({ contextSource: childData }, () => {
			if (childData.play) {
				this.handleShowPlayer();
			}
		});
	};

	callbackInfoScreenPlay = childData => {
		this.setState({ infoSource: childData }, () => {
			var showItemOverlay = new CustomEvent('showItemOverlay', {
				detail: {
					content: (
						<Overlay
							type='infoplayer'
							contextSource={this.state.contextSource}
							callbackInfoScreenPlay={this.callbackInfoScreenPlay}
						/>
					),
					bgImg: this.state.infoSource.artUrl
				},
			});
			document.dispatchEvent(showItemOverlay);
		});
	};

	handleDownloadFile() {
		downloadFile(this.state.contextSource.vidLocation);
	}

	handleShowPlayer() {
		if (this.props.debug) console.log('Context Source', this.state.contextSource);
		let bgImg = this.state.contextSource !== null ? this.state.contextSource.artURL : PlayerBG;
		var showItemOverlay = new CustomEvent('showItemOverlay', {
			detail: { content: <Overlay
				type='player'
				contextSource={this.state.contextSource}
				callbackInfoScreenPlay={this.callbackInfoScreenPlay}
			/>, bgImg: bgImg },
		});
		document.dispatchEvent(showItemOverlay);
	}

	async handleGenerateAriaLinks(childData) {
		var showItemOverlay = new CustomEvent('showItemOverlay', {
			detail: {
				content: (
					<Overlay
						type='ariaList'
						contextSource={this.state.contextSource}
					/>
				)
			},
		});
		document.dispatchEvent(showItemOverlay);
	}

	handleAriaRPC() {
		var showItemOverlay = new CustomEvent('showItemOverlay', {
			detail: {
				content: (
					<Overlay
						type='ariaFetchLoader'
						contextSource={this.state.contextSource}
					/>
				)
			},
		});
		document.dispatchEvent(showItemOverlay);
	}

	handleAddtoQueue() {
		alert('This is AddToQueue placeholder');
	}

	handleShowInfo() {
		var showItemOverlay = new CustomEvent('showItemOverlay', {
			detail: { content: <Overlay
				type='info'
				contextSource={this.state.contextSource}
				callbackInfoScreenPlay={this.callbackInfoScreenPlay}
			/>, bgImg: this.state.contextSource.artUrl },
		});
		document.dispatchEvent(showItemOverlay);
	}

	showMore = async e => {
		this.setState({
			page: this.state.page + 1
		})
	};

	renderItem = (itm, nsfw_list) => {
		var isNSFW = false;
		var isHidden = false;

		let isActive = itm.movies.find(item => {
			return item.library.resource 
			&& item.library.resource.presence == '1';
		});

		console.log(nsfw_list);
		console.log(itm);
		
		let checkNSFW = itm.movies.find(item => {
			return nsfw_list.data.find(nsfw => {
				return nsfw.itm_id === item.library._id;
			});
		});

		if (checkNSFW !== undefined) {
			isNSFW = true;
		}

		if (isActive) {
			return (
				<VideoPoster
					key={uuidv4()}
					count={itm.count}
					vidItems={itm.movies.filter(item =>
						item.library.resource 
						&& item.library.resource.presence == '1')}
					vid_audioCodec={itm.vid_audioCodec}
					vid_duration={itm.vid_duration}
					vid_height={itm.vid_height}
					vid_title={itm.vid_title}
					vid_videoCodec={itm.vid_videoCodec}
					vid_width={itm.vid_width}
					isNSFW={isNSFW}
					isHidden={isHidden}
					parentCallback={this.callbackChildSources}
				/>
			);
		}
	}

	getNoResultsScreen() {
		if (!this.state.user || this.state.user.safeSearch) {
			return (
				<div className='vid-list-msg'>
					Dear sir/madam,
					<br />
					<br />I am writing to inform you that your search has returned no data.
					<br />
					<br />
					Cordially yours,
					<br />
					PlexWeb
					<br />
					<img src={Spiff} alt='spiffing brit' />
				</div>
			);
		} else {
			return (
				<div className='vid-list-msg text-center'>
					NAH M8 NOTHIN FOUND SO FUCK OFF
					<br />
					<img src={Spooge} alt='spooging brit' />
				</div>
			);
		}
	}

	getResultsCount() {
		let results = null;

		(!this.state.user || this.state.user.safeSearch)
			? (results = document.querySelectorAll('.vid-item:not(.nsfw)').length)
			: (results = document.querySelectorAll('.vid-item').length);

		return results;
	}

	render() {
		return (
			<div>
				<div className='searchbar-container'>
					<form onSubmit={this.handleSubmit}>
						<input
							type='text'
							name='search'
							className='searchbar'
							placeholder='Movie Search'
							disabled={this.state.isLoading ? 'disabled' : ''}
						/>
					</form>
				</div>
				<div className='results-container'>
					{this.state != null ? (
						<div className='rc'>
							<div className='results-count'>
								Results (
								{this.state.isLoading ? this.getResultsCount() : this.getResultsCount()})
							</div>
							<div className='results-filter'>
								<VideoFilter searchType='Movie' />
							</div>
						</div>
					) : null}
				</div>

				{!this.state.firstload ? (<VideoPosters
					query={this.state.query}
					page={this.state.page}
					nsfw_list={this.state.nsfw_list}
					onLoaded={() => {
						console.log("setOnLoaded");
						if (!this.state.allowViewportMonitor)
							this.setState({allowViewportMonitor: true})
					}}
					safeSearch={!this.state.user || this.state.user.safeSearch}
					renderItem={this.renderItem}
				/>) : <MessageScreen />}

				<div className='plexwebContextMenu'>
					<ul>
						<li>
							<button onClick={() => this.handleDownloadFile()} id='plexContextDownload'>
								<i className='fa fa-download' /> Download
							</button>
						</li>
						<li>
							<button onClick={() => this.handleShowPlayer()} id='plexContextPlay'>
								<i className='fa fa-play' /> Play Video
							</button>
						</li>
						<li>
							<a
								href='/#'
								onClick={async () => await this.handleGenerateAriaLinks()}
								style={{ textDecoration: 'none' }}
								className='downloadList tv-aria-list'>
								<button id='plexContextAria'>
									<i className='fa fa-cogs' /> Aria List
								</button>
							</a>
						</li>
						<li>
							<button id='plexContextAriaPush' onClick={() => this.handleAriaRPC()}>
								<i className='fab fa-asymmetrik' /> Aria RPC
							</button>
						</li>
						<li className='hidden'>
							<button onClick={() => this.handleAddtoQueue()} id='plexContextQueue'>
								<i className='fa fa-plus' /> Add to Queue
							</button>
						</li>
						<li>
							<div className='context-seperator' />
						</li>
						<li>
							<button onClick={() => this.handleShowInfo()} id='plexContextShowInfo'>
								<i className='fa fa-eye' /> Show Info
							</button>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

export default withContext(MovieListings);
