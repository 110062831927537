import React from 'react';
import axios from 'axios';
import { withContext } from './../Persistant/AppContext';

//Auth
import { Route, Switch } from 'react-router-dom';
import Signup from './../Auth/CreateUser';
import Login from './../Auth/Login';
import ProtectedRoute from './../Auth/ProtectedRoute';

// Main App
import App from './App';

// Stylesheets
import 'react-mdl/extra/material.css';
import './../Assets/sass/mdl-expansion.scss';
import './../Assets/css/TokenView.css';

// JS
import 'react-mdl/extra/material.js';

axios.defaults.withCredentials = true

function MdlExpansion(props) {
	const [ expanded, setExpanded ] = React.useState(false);
	return (
		<React.Fragment>
			<details className="mdl-expansion">
				<summary
					className="mdl-expansion__summary"
					onClick={(e) => {
						setExpanded(!expanded);
						props.callback && props.callback(!expanded);
					}}
				>
					<span className="mdl-expansion__header">{props.header}</span>
				</summary>
			</details>
			{expanded ? <div className="mdl-expansion__content">{props.children}</div> : null}
		</React.Fragment>
	);
}

function PlexMediaItem(props) {
	/*
    const getTranscodeUrl = function(server_data, url, size){
        return( `http://${server_data.s_address}:${server_data.s_port}/photo/:/transcode?width=${size}&height=${size}&minSize=1&url=${encodeURIComponent(url)}&X-Plex-Token=${server_data.s_accessToken}`);
    }
    */
	const getUrl = function(server_data, key) {
		return `http://${server_data.s_address}:${server_data.s_port}${key}?X-Plex-Token=${server_data.s_accessToken}`;
	};
	return (
		<a href={getUrl(props.server_data, props.data.Part[0].key)}>
			<img
				height={300}
				alt="thumb img"
				src={/*getTranscodeUrl(props.server_data,*/ getUrl(props.server_data, props.thumb) /*,200)*/}
			/>
		</a>
	);
}

function Show(props) {
	const [ expanded, setExpanded ] = React.useState(false);
	const [ isLoading, setIsLoading ] = React.useState(false);
	return (
		<MdlExpansion header={props.name}>
			{props.data ? (
				props.data.map((item, key) => (
					<PlexMediaItem
						server_data={props.server_data}
						thumb={item.thumb}
						art={item.art}
						lib_data={props.lib_data}
						data={item.Media[0]}
					/>
				))
			) : null}
		</MdlExpansion>
	);
}

function Library(props) {
	const [ expanded, setExpanded ] = React.useState(false);
	const [ libraryData, setLibraryData ] = React.useState(null);
	const [ isLoading, setIsLoading ] = React.useState(false);

	const getLibraryData = async function(server_data, library_data) {
		setIsLoading(true);
		let url = `http://${server_data.s_address}:${server_data.s_port}/library/sections/${library_data.key}/all?X-Plex-Token=${server_data.s_accessToken}${library_data.type ===
		'show'
			? '&type=4'
			: ''}`;
		let response = await axios.get(url);
		console.log(response.data);
		if ((await response.data) != null) {
			setLibraryData(
				library_data.type === 'show'
					? response.data.MediaContainer.Metadata.reduce(function(rv, x) {
							let group = x['grandparentTitle'];
							rv[group] = rv[group] || [];
							rv[group].push(x);
							return rv;
						}, {})
					: response.data.MediaContainer.Metadata
			);
			setIsLoading(false);
		}
	};

	if (!isLoading && expanded && libraryData == null) {
		getLibraryData(props.server_data, props.lib_data);
	}

	return (
		<MdlExpansion callback={(isExpanded) => setExpanded(isExpanded)} header={props.lib_data.title}>
			{!isLoading && libraryData ? libraryData.map ? (
				libraryData.map((item, key) => (
					<div key={key}>
						<PlexMediaItem
							server_data={props.server_data}
							thumb={item.thumb}
							art={item.art}
							lib_data={props.lib_data}
							data={item.Media[0]}
						/>
					</div>
				))
			) : (
				Object.keys(libraryData).map((item, key) => (
					<Show
						name={item}
						server_data={props.server_data}
						thumb={libraryData[item][0].thumb}
						art={libraryData[item][0].art}
						lib_data={props.lib_data}
						data={libraryData[item]}
					/>
				))
			) : null}
		</MdlExpansion>
	);
}

function Server(props) {
	const [ expanded, setExpanded ] = React.useState(false);
	const [ libraries, setLibraries ] = React.useState(null);

	const getLibraries = async function(server_data) {
		try {
			let url = `http://${server_data.s_address}:${server_data.s_port}/library/sections?X-Plex-Token=${server_data.s_accessToken}`;
			let response = await axios.get(url);
			// let directories = response.data.MediaContainer.Directory
			setLibraries(response.data.MediaContainer.Directory);
		} catch (e) {
			setLibraries({});
		}
	};

	if (libraries == null) {
		getLibraries(props.data);
	}

	return (
		<MdlExpansion header={props.data.s_name}>
			{libraries ? libraries.map((data) => <Library server_data={props.data} lib_data={data} />) : null}
		</MdlExpansion>
	);
}

function Token(props) {
	const [ tokenData, setTokenData ] = React.useState(null);
	const getTokenData = async function(token) {
		let response = await axios.get(`/api/token/${token}`);
		let result = [];
		for (let i of await response.data) {
			if (
				result.filter(function(item, index) {
					return item.s_machine_id === i.s_machine_id;
				}).length === 0
			)
				result.push(i);
		}
		setTokenData(result);
	};

	if (tokenData == null) {
		getTokenData(props.match.params.token);
	}

	return (
		<div className="white">
			{tokenData != null ? tokenData.map((server, key) => <Server data={server} key={key} />) : 'Loading...'}
		</div>
	);
}
let TokenContext = withContext(Token);

const isInViewPort = function(ele) {
	try {
		var bounding = ele.getBoundingClientRect();
		return (
			bounding.top >= 0 &&
			bounding.left >= 0 &&
			bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	} catch (e) {
		void 0;
	}
};

function onScroll() {
	// Get trigger element
	var visind = document.querySelector('.vis-indicator');
	// Test if in Viewport
	if (isInViewPort(visind)) {
		let ev = new Event('IsInViewport');
		document.dispatchEvent(ev);
	}
}

function EntryPoint() {
	return (
		<div className="app-wrapper" onScroll={() => onScroll()}>
			<Switch> 
				<Route path="/signup" component={Signup} />
				<Route path="/login" component={Login} />
				<ProtectedRoute path="/token/:token?" component={TokenContext} />
				<ProtectedRoute path="/token/:token/:server_id?" component={TokenContext} />
				<ProtectedRoute exact path="/" component={App} />
				<ProtectedRoute path="/*" component={App} />
			</Switch>
		</div>
	);
}

export default EntryPoint;
