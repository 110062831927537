import React, { Component } from 'react';
import axios from 'axios';
import { VideoQuickInfo } from './tpl_video_quick_info';
import VideoImage from './tpl_video_img';
import { withContext } from './../../../Persistant/AppContext';
import { fireEvent, extractMediaTitle } from '../../../App.Events';
axios.defaults.withCredentials = true

class VideoPoster extends Component {
	constructor(props) {
		super(props);
		this.state = {
			vidTitle: this.props.vid_title
		};
		this.user = JSON.parse(localStorage.getItem('user'));
	}

	showHoverTitle(title) {
		VideoQuickInfo(this.props.vid_title, this.props.vidItems[0]);
	}

	hideHoverTitle() {
		fireEvent('closeSmOverlay');
	}

	//handleReport = async (libid) => {
	async handleReport(libid) {
		var res = window.confirm('Are you sure you wish to report Library: ' + libid + '?');
		if (res === true) {
			alert('Library has been added to NSFW list');

			let offending_item = document.querySelectorAll(`.lib-${this.props.vidItems[0].library._id}`);
			offending_item.forEach(function(i) {
				!this.user || this.user.safeSearch ? i.classList.add('nsfw', 'hidden') : i.classList.add('nsfw');
			}.bind(this));

			const apiEndpoint = '/api';
			const nsfw_url = `${apiEndpoint}/report_nsfw/${libid}`;
			await axios(nsfw_url);
		}
	}

	getVideoTitle() {
		const result = extractMediaTitle(this.props.vidItems[0].part_file);
		if (result !== null) {
			return result.title;
		} else {
			return this.props.vidItems.vid_title;
		}
	}

	getNSFWSetting() {
		const prefix = `vid-item lib-${this.props.vidItems[0].library._id}`;
		if (this.props.isNSFW) {
			if ((!this.user || this.user.safeSearch)) {
				return prefix + ' nsfw hidden';
			} else {
				return prefix + ' nsfw';
			}
		} else {
			return prefix;
		}
	}

	getGeo = async (ip) => {
		const apiEndpoint = '/api';
		const geo_url = `${apiEndpoint}/GeoCheck/` + ip;
		let geo_details
		try {
			geo_details = await axios(geo_url);
			if (geo_details.status == 401)
				this.props.unauthed();
		} catch (ex) {
			console.log(ex)
			this.props.unauthed();
		}
		await this.setState({
			geo: geo_details.data
		});
	};

	componentDidMount() {
		//this.getGeo(this.props.vidItems[0].library.resource.Connection[0].address);
	}

	getFlag() {
		let img = 'images/Flags/4x3/' + this.state.geo + '.svg';
		return (
			<img className="geo-flag" width="15px" src={img} alt={this.state.geo} title={'Geo: ' + this.state.geo} />
		);
	}

	getCodec() {
		switch (this.props.vidItems[0].vid_videoCodec) {
			case 'mpeg1video':
				return 'MPEG-1';
			case 'mpeg2video':
				return 'MPEG-2';
			case 'msmpeg4':
				return 'MSMPG4';
			case 'msmpeg4v1':
				return 'MSMPG4';
			case 'msmpeg4v2':
				return 'MSMPG4';
			case 'msmpeg4v3':
				return 'MSMPG4';
			case 'msvideo1':
				return 'MSVID1';
			case 'rawvideo':
				return 'RAW';
			case null:
				return 'NA';
			default:
				return this.props.vidItems[0].vid_videoCodec;
		}
	}

	getResolution() {
		switch (this.props.vidItems[0].vid_resolution) {
			case null:
				return 'n/a';
			default:
				return this.props.vidItems[0].vid_resolution;
		}
	}

	parentCallback = (childData) => {
		this.props.parentCallback(childData);
	};
	
	render() {
		return (
			<div
				className={this.getNSFWSetting()}
				vidyear={this.props.vidItems[0].vid_year}
				vidres={this.getResolution()}
				vidtitle={this.props.vidItems[0].vid_title}
			>
				<div className="vid-header">
					<div className="vid-geo">
						<span className="spanTitle">
							{this.getResolution()} {this.getCodec()}
						</span>
					</div>
					<div className="vid-report">
						<i
							className="fa fa-exclamation-triangle"
							title={'Report Library ID: ' + this.props.vidItems[0].library._id}
							libid={this.props.vidItems[0].library._id}
							onClick={() => this.handleReport(this.props.vidItems[0].library._id)}
						/>
					</div>
				</div>

				<VideoImage
					fileDetails={{
						newTitle: this.props.vid_title,
						oldTitle: this.props.vidItems[0].vid_title,
						uri: this.props.vidItems[0].library.resource.Connection[0].uri,
						s_accessToken: this.props.vidItems[0].library.resource.accessToken,
						part_key: this.props.vidItems[0].part_key,
						vid_thumb: this.props.vidItems[0].vid_thumb,
						sources: this.props.vidItems
						//newItem: this.props.vidItems[0].newItem
					}}
					parentCallback={this.parentCallback}
				/>
				<div className="vid-title ellipsis" onClick={() => this.showHoverTitle()}>
					{this.state.vidTitle}
				</div>
				<div className="vid-extras">
					<div className="vid-extras-content-left">
						Year: <span className="vid-year">{this.props.vidItems[0].vid_year}</span>
					</div>
					<div className="vid-extras-content-right">
						<span className="vid-count">{this.props.count}</span> videos
					</div>
				</div>
			</div>
		);
	}
}

export default withContext(VideoPoster);
