import React, { Component } from 'react';
import { withContext } from '../../../Persistant/AppContext';
import ReactPlayer from 'react-player';
import { findDOMNode } from 'react-dom';
import { downloadFile, closeItemOverlay } from './../../../App.Events';
import { Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import screenfull from 'screenfull';

class VideoPlayer extends Component {
    // ##########################################
    //              Custom Functions
    // ##########################################
    padNumber(n){
        return parseInt(n) < 10 ? '0' + n : n;
    }

    handleKeyPresses = (e) => {
        switch (e.keyCode) {
            case 27: // ESC
                if (this.props.debug)
                    console.log('PLAYER: [ESC Key] was pressed (Stopping Video, Closing Overlay, Unloading Player)');
                this.stop({});
                break;
            case 32: // SPACE BAR
                console.log('PLAYER: [Space Key] was pressed (Toggle Play/Pause)');
                this.playPause({});
                break;
            default:
                break;
        }
    };

    // ##########################################
    //              Init Config
    // ##########################################
    constructor(props) {
        super(props);
    } 
    
    state = {
        url: null,
        pip: false,
        player: true,
        controls: true,
        light: true,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        vidEPList: this.props.vidEPList,
        vidEPListIndex: -1,
        sentCallback: false
    };

    load = (url) => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false
        });
        //console.log('Player State', this.state);
    };
    
    // ##########################################
    //              Mount / UnMount
    //          Prevents Memory Leaks
    // ##########################################
    componentDidMount() {
        document.addEventListener('keyup', this.handleKeyPresses, false);        
    }
    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyPresses, false);
    }
    // Helper Function 
    generateVideoDetails(){
        if (this.state.vidEPListIndex <= this.state.vidEPList.length){
            let nextIndex = this.state.vidEPListIndex + 1;
            let list = this.state.vidEPList;
            let filename = `${this.props.vidParent} - S${this.padNumber(list[nextIndex].vid_season_num)}E${this.padNumber(list[nextIndex].vid_episode_num)} - ${list[nextIndex].vid_title}`;
            let url = `http://${list[nextIndex].server.s_address}:${list[nextIndex].server.s_port}${list[nextIndex].part_key}?X-Plex-Token=${list[nextIndex].server.s_accessToken}&download=1`;
            let art = `http://${list[nextIndex].server.s_address}:${list[nextIndex].server.s_port}/photo/:/transcode?width=556&height=692&minSize=1&upscale=1&url=${encodeURI(list[nextIndex].vid_art + '&X-Plex-Token=' + list[nextIndex].server.s_accessToken,)}&X-Plex-Token=${list[nextIndex].server.s_accessToken})}`;
            
            this.setState({
                vidEPListIndex: nextIndex,
                vidTitle: filename,
                url: url,
                light: art,
                playing: true
            });
        }
    }

    // ##########################################
    //              Player Actions
    // ##########################################
    playNextItem = () => {

    }
    playPause = () => {
        this.setState({ playing: !this.state.playing });
    };
    stop = () => {
        if (this.props.debug) console.log('Player: [STOPPED]');
        this.setState({ url: null, playing: false });
    };
    toggleControls = () => {
        const url = this.state.url;
        if (this.props.debug) console.log('PLAYER: [Toggled Controls]');
        this.setState(
            {
                controls: !this.state.controls,
                url: null
            },
            () => this.load(url)
        );
    };
    toggleLight = () => {
        if (this.props.debug) console.log('PLAYER: [Toggled Light Player]');
        this.setState({ light: !this.state.light });
    };
    toggleLoop = () => {
        if (this.props.debug) console.log('PLAYER: [Toggled Loop]');
        this.setState({ loop: !this.state.loop });
    };
    setVolume = (e) => {
        if (this.props.debug) console.log('PLAYER: [Changed Volume: ' + e.target.value + ']');
        this.setState({ volume: parseFloat(e.target.value) });
    };
    onMuted = () => {
        if (this.props.debug) console.log('PLAYER: [MUTED]');
        this.setState({ muted: !this.state.muted });
    };
    setPlaybackRate = (e) => {
        if (this.props.debug) console.log('PLAYER: [Changed Playback Rate: ' + e.target.value + ']');
        this.setState({ playbackRate: parseFloat(e.target.value) });
    };
    togglePIP = () => {
        if (this.props.debug) console.log('PLAYER: [Toggled PIP]');
        this.setState({ pip: !this.state.pip });
    };
    onPlay = () => {
        if (this.props.debug) console.log('PLAYER: [onPlay EVENT]');
        this.setState({ playing: true });
    };
    onEnablePIP = () => {
        if (this.props.debug) console.log('PLAYER: [Enabled PIP]');
        this.setState({ pip: true });
    };
    onDisablePIP = () => {
        if (this.props.debug) console.log('PLAYER: [onDisablePIP EVENT]');
        this.setState({ pip: false });
    };
    onPause = () => {
        if (this.props.debug) console.log('PLAYER: [onPause EVENT]');
        this.setState({ playing: false });
    };
    onSeekMouseDown = (e) => {
        if (this.props.debug) console.log('PLAYER: [onSeekMouseDown EVENT]');
        this.setState({ seeking: true });
    };
    onSeekChange = (e) => {
        if (this.props.debug) console.log('PLAYER: [onSeekChange EVENT]');
        this.setState({ played: parseFloat(e.target.value) });
    };
    onSeekMouseUp = (e) => {
        if (this.props.debug) console.log('PLAYER: [onSeekMouseUp EVENT]');
        this.setState({ seeking: false });
        this.player.seekTo(parseFloat(e.target.value));
    };
    onProgress = (state) => {
        if (this.props.debug) console.log('PLAYER: [onProgress EVENT] - ', state);
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state);
        }
    };
    onEnded = () => {
        if (this.props.debug) console.log('PLAYER: [onEnded EVENT]');
        this.setState({ playing: this.state.loop });
    };
    onDuration = (duration) => {
        if (this.props.debug) console.log('PLAYER: [onDuration EVENT] - ', duration);
        this.setState({ duration });
    };
    onClickFullscreen = () => {
        if (this.props.debug) console.log('PLAYER: [onClickFullScreen EVENT]');
        screenfull.request(findDOMNode(this.player));
    };
    ref = (player) => {
        this.player = player;
    };
    onReady = () => {
        console.log('onReady INIT');
        this.props.parentCallback(this.state.vidEPListIndex);
    }

    renderPlayer(){
        const { playing, controls, light, volume, muted, loop, playbackRate, pip } = this.state;
        // notify parent of current source index        
        return <ReactPlayer
            ref={this.ref}
            className="react-player-naked"
            url={this.state.url}
            pip={pip}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={() => this.onReady}
            onStart={() => console.log('onStart INIT')}
            onPlay={this.onPlay}
            onEnablePIP={this.onEnablePIP}
            onDisablePIP={this.onDisablePIP}
            onPause={this.onPause}
            onBuffer={() => console.log('onBuffer')}
            onSeek={(e) => console.log('onSeek', e)}
            onEnded={this.onEnded}
            onError={(e) => console.log('onError', e)}
            onProgress={this.onProgress}
            onDuration={this.onDuration}
        />        
    }

    render() {
        const { playing, controls, light, volume, muted, loop, playbackRate, pip } = this.state;
        //const SEPARATOR = ' . '

        return (
            <div>
                <div className="player-title">{this.state.vidTitle}</div>
                <div className="player-naked">
                    {/* this.state.url ? (this.renderPlayer(), console.log('ref', this.ref)) : this.generateVideoDetails() */}
                    {this.renderPlayer()}{this.state.url ? null : this.generateVideoDetails()}
                    <div className="player-download">
                        <Button
                            style={{ color: '#fff' }}
                            colored
                            onClick={() => this.generateVideoDetails()}
                        >
                            Source ({ `${this.state.vidEPListIndex + 1} of ${this.state.vidEPList.length}` })
                        </Button>
                        <Button
                            style={{ color: '#fff' }}
                            colored
                            onClick={() => downloadFile(this.state.url)}
                        >
                            Download Video
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withContext(VideoPlayer);
