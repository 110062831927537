import React from 'react';
import Main from '../Routes/main';
import { CreateListeners } from './../App.Events';
import LargeOverlay from '../Components/tpl/ListingHelpers/tpl_video_overlay';

// Stylesheets
import 'react-mdl/extra/material.css';
//import './../Assets/sass/main-style.scss';
import './../Assets/css/animate.css';

function App(props) {
	// Handles creating event listeners from App.Events.js file
	CreateListeners();

	return (
		<div>
			<div className="container-main">
				<Main />
				<LargeOverlay />

				<div id="sm-overlay" className="sm-overlay" hidden />
			</div>
		</div>
	);
}

export default App;
