import React, { Component } from 'react';
import { uuidv4 } from '../../../App.Events';
import noPoster from '../../../Assets/Images/no-poster.jpg';

class VideoImage extends Component {
	constructor(props) {
		super(props);
		this.vidFileLocation = `${this.props.fileDetails.sources[0].library.resource.Connection[0].uri}${this.props.fileDetails.sources[0].part_key}?X-Plex-Token=${this.props.fileDetails.
			sources[0].library.resource.Connection[0].accessToken}&download=1`;
		this.posterURL = `${this.props.fileDetails.sources[0].library.resource.Connection[0].uri}/photo/:/transcode?width=127&height=189&minSize=1&upscale=1&url=${encodeURI(
			this.props.fileDetails.sources[0].vid_thumb +
				'&X-Plex-Token=' +
				this.props.fileDetails.sources[0].library.resource.accessToken
		)}&X-Plex-Token=${this.props.fileDetails.sources[0].library.resource.accessToken}`;
		this.currentSource = 0;
		this.imgErrHandle = true;
	}

	handleImageError(e) {
		e.target.src = noPoster;
	}

	// Generate New Item Sash
	generateNewItemSash() {
		if (this.props.fileDetails.newItem === true) {
			return <div className="vid-img-new" />;
		}
	}

	contextHandler(e, play = null) {
		let artURL = `${this.props.fileDetails.sources[0].library.resource.Connection[0].uri}/photo/:/transcode?width=1904&height=494&minSize=1&opacity=30&background=36383b&url=${encodeURI(
			this.props.fileDetails.sources[0].vid_thumb +
				'&X-Plex-Token=' +
				this.props.fileDetails.sources[0].library.resource.accessToken
		)}&X-Plex-Token=${this.props.fileDetails.sources[0].library.resource.accessToken}`;
		artURL = artURL.replace('thumb', 'art');
		let vidFileLocation = `${this.props.fileDetails.sources[0].library.resource.Connection[0].uri}${this.props.fileDetails.sources[0].part_key}?X-Plex-Token=${this.props.fileDetails.sources[0].library.resource.accessToken}&download=1`;

		//Build callback Data for parent
		let newTitle = this.props.fileDetails.sources[0].vid_title;
		let callbackData = {
			play: play,
			newTitle: `${newTitle}`,
			oldTitle: `${newTitle}`,
			vidLocation: `${vidFileLocation}`,
			artUrl: `${artURL}`,
			menuUUID: uuidv4(),
			sources: this.props.fileDetails.sources
		};
		// Pass data of this item back to the MovieListing Parent
		this.props.parentCallback(callbackData);

		// Show Custom Context Menu
		if (play !== null) {
			var customMenu = new CustomEvent('movieContextMenu', { detail: { e } });
			document.dispatchEvent(customMenu);
		}
	}

	render() {
		return (
			<div>
				<div className="vid_img toolip">
					<img
						src={this.posterURL}
						alt="poster"
						uuid={this.MenuUUID}
						onClick={() => this.contextHandler(this, true)}
						onContextMenu={() => this.contextHandler(this)}
						onError={this.handleImageError.bind(this)}
						className="vid_poster_img"
					/>
				</div>
			</div>
		);
	}
}

export default VideoImage;
