import React, { Component } from 'react';
import { withContext } from '../../../Persistant/AppContext';
import ReactPlayer from 'react-player';
import { findDOMNode } from 'react-dom';
import { downloadFile, closeItemOverlay } from './../../../App.Events';
import { Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import screenfull from 'screenfull';

class VideoPlayer extends Component {
	// ##########################################
	//              Custom Functions
	// ##########################################

	handleKeyPresses = (e) => {
		switch (e.keyCode) {
			case 27: // ESC
				if (this.props.debug)
					console.log('PLAYER: [ESC Key] was pressed (Stopping Video, Closing Overlay, Unloading Player)');
				this.stop({});
				break;
			case 32: // SPACE BAR
				console.log('PLAYER: [Space Key] was pressed (Toggle Play/Pause)');
				this.playPause({});
				break;
			default:
				break;
		}
	};

	// ##########################################
	//              Init Config
	// ##########################################
	state = {
		url: null,
		pip: false,
		player: true,
		controls: true,
		light: false,
		volume: 0.8,
		muted: false,
		played: 0,
		loaded: 0,
		duration: 0,
		playbackRate: 1.0,
		loop: false
	};
	load = (url) => {
		this.setState({
			url,
			played: 0,
			loaded: 0,
			pip: false
		});
	};

	// ##########################################
	//              Mount / UnMount
	//          Prevents Memory Leaks
	// ##########################################
	componentWillMount() {
		document.addEventListener('keyup', this.handleKeyPresses, false);
	}
	componentWillUnmount() {
		document.removeEventListener('keyup', this.handleKeyPresses, false);
	}

	// ##########################################
	//              Player Actions
	// ##########################################
	playPause = () => {
		this.setState({ playing: !this.state.playing });
	};
	stop = () => {
		if (this.props.debug) console.log('Player: [STOPPED]');
		this.setState({ url: null, playing: false });
	};
	toggleControls = () => {
		const url = this.state.url;
		if (this.props.debug) console.log('PLAYER: [Toggled Controls]');
		this.setState(
			{
				controls: !this.state.controls,
				url: null
			},
			() => this.load(url)
		);
	};
	toggleLight = () => {
		if (this.props.debug) console.log('PLAYER: [Toggled Light Player]');
		this.setState({ light: !this.state.light });
	};
	toggleLoop = () => {
		if (this.props.debug) console.log('PLAYER: [Toggled Loop]');
		this.setState({ loop: !this.state.loop });
	};
	setVolume = (e) => {
		if (this.props.debug) console.log('PLAYER: [Changed Volume: ' + e.target.value + ']');
		this.setState({ volume: parseFloat(e.target.value) });
	};
	onMuted = () => {
		if (this.props.debug) console.log('PLAYER: [MUTED]');
		this.setState({ muted: !this.state.muted });
	};
	setPlaybackRate = (e) => {
		if (this.props.debug) console.log('PLAYER: [Changed Playback Rate: ' + e.target.value + ']');
		this.setState({ playbackRate: parseFloat(e.target.value) });
	};
	togglePIP = () => {
		if (this.props.debug) console.log('PLAYER: [Toggled PIP]');
		this.setState({ pip: !this.state.pip });
	};
	onPlay = () => {
		if (this.props.debug) console.log('PLAYER: [onPlay EVENT]');
		this.setState({ playing: true });
	};
	onEnablePIP = () => {
		if (this.props.debug) console.log('PLAYER: [Enabled PIP]');
		this.setState({ pip: true });
	};
	onDisablePIP = () => {
		if (this.props.debug) console.log('PLAYER: [onDisablePIP EVENT]');
		this.setState({ pip: false });
	};
	onPause = () => {
		if (this.props.debug) console.log('PLAYER: [onPause EVENT]');
		this.setState({ playing: false });
	};
	onSeekMouseDown = (e) => {
		if (this.props.debug) console.log('PLAYER: [onSeekMouseDown EVENT]');
		this.setState({ seeking: true });
	};
	onSeekChange = (e) => {
		if (this.props.debug) console.log('PLAYER: [onSeekChange EVENT]');
		this.setState({ played: parseFloat(e.target.value) });
	};
	onSeekMouseUp = (e) => {
		if (this.props.debug) console.log('PLAYER: [onSeekMouseUp EVENT]');
		this.setState({ seeking: false });
		this.player.seekTo(parseFloat(e.target.value));
	};
	onProgress = (state) => {
		if (this.props.debug) console.log('PLAYER: [onProgress EVENT] - ', state);
		// We only want to update time slider if we are not currently seeking
		if (!this.state.seeking) {
			this.setState(state);
		}
	};
	onEnded = () => {
		if (this.props.debug) console.log('PLAYER: [onEnded EVENT]');
		this.setState({ playing: this.state.loop });
	};
	onDuration = (duration) => {
		if (this.props.debug) console.log('PLAYER: [onDuration EVENT] - ', duration);
		this.setState({ duration });
	};
	onClickFullscreen = () => {
		if (this.props.debug) console.log('PLAYER: [onClickFullScreen EVENT]');
		screenfull.request(findDOMNode(this.player));
	};
	ref = (player) => {
		this.player = player;
	};

	render() {
		const { playing, controls, light, volume, muted, loop, playbackRate, pip } = this.state;
		//const SEPARATOR = ' . '

		return (
			<div id="player-container" className="player-container">
				<Card shadow={2} style={{ width: '640px', height: '100%', margin: 'auto', backgroundColor: '#2c3c44' }}>
					<CardTitle
						style={{
							backgroundColor: '#292f35',
							color: '#fff',
							height: '58px',
							textAlign: 'center',
							borderBottom: '1px solid white'
						}}
					>
						<div style={{ width: '100%' }}>{this.props.vid_title}</div>
						<div>
							<i className="fa fa-times-circle red_hover" onClick={() => closeItemOverlay()} />
						</div>
					</CardTitle>
					<CardText style={{ backgroundColor: '#2c3c44', margin: '0 auto' }}>
						<ReactPlayer
							ref={this.player}
							className="react-player"
							url={this.props.vid_file_location}
							pip={pip}
							playing={playing}
							controls={controls}
							light={light}
							loop={loop}
							playbackRate={playbackRate}
							volume={volume}
							muted={muted}
							onReady={() => console.log('onReady INIT')}
							onStart={() => console.log('onStart INIT')}
							onPlay={this.onPlay}
							onEnablePIP={this.onEnablePIP}
							onDisablePIP={this.onDisablePIP}
							onPause={this.onPause}
							onBuffer={() => console.log('onBuffer')}
							onSeek={(e) => console.log('onSeek', e)}
							onEnded={this.onEnded}
							onError={(e) => console.log('onError', e)}
							onProgress={this.onProgress}
							onDuration={this.onDuration}
						/>
					</CardText>
					<CardActions border style={{ backgroundColor: '#292f35', borderTop: '1px solid white' }}>
						<Button
							style={{ color: '#fff' }}
							colored
							onClick={() => downloadFile(this.props.vid_file_location)}
						>
							Download Video
						</Button>
					</CardActions>
				</Card>
			</div>
		);
	}
}

export default withContext(VideoPlayer);
