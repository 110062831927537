import React, { Component } from 'react';
import { withContext } from '../../Persistant/AppContext';
import axios from 'axios';
import TVPoster from '../tpl/ListingHelpers/tpl_tv_poster';
import TVEpisodes from '../tpl/ListingHelpers/tpl_tv_episodes';
import VideoFilter from '../tpl/ListingHelpers/tpl_video_filter';
import noPoster from '../../Assets/Images/no-poster.jpg';
axios.defaults.withCredentials = true


class TVListings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			firstload: true,
			items: [],
			user: JSON.parse(localStorage.getItem('user')),
		};
	}

	componentDidMount() {
		this.setState({
			isLoading: false,
			items: [],
			query: '',
		});
	}

	handleSubmit = async e => {
		window.stop();
		e.preventDefault();
		this.firstload = false;
		this.state.isLoading = true;

		const apiEndpoint = '/api';
		const nsfw_url = `${apiEndpoint}/nsfw/`;
		let nsfw_list
        try {
            nsfw_list = await axios(nsfw_url);
            if (nsfw_list.status == 401)
                this.props.unauthed();
        } catch (ex) {
            console.log(ex)
            this.props.unauthed();
        }

		this.setState({ firstload: false });

		this.state.query = document.querySelector('.searchbar').value;

		//AutoQuote searches.
		this.state.query = this.state.query.match(/"/)
			? this.state.query
			: this.state.query
					.split(' ')
					.map(function(item) {
						return item.match(/^-/) ? item : `"${item}"`;
					})
					.join(' ');

		const api_url = `${apiEndpoint}/show_titles/${this.state.query}`;

		this.setState({
			isLoading: true,
		});

		let result 
		try {
			result = await axios(api_url);
			if (result.status == 401)
				this.props.unauthed();
		} catch (ex) {
			console.log(ex)
			this.props.unauthed();
		}

		console.log(result);

		let orderedList = [];
		result.data.map(item => {
			item.relevancy = Math.round(this.getRelevancy(
				item._id.vid_grandparent_title.toLowerCase(),
				this.state.query.toLowerCase(),
			) * 1) / 1;
		});

		console.log(result.data);

		this.setState({
			isLoading: false,
			items: result.data.sort(
				//Sort items by vid count desc.
				(a, b) => (b.relevancy > a.relevancy ? 1 : -1),
			),
			nsfw_list: nsfw_list,
		});
	};

	renderItem(itm) {

		console.log('Render Item', itm);

		let key = Date.now() + Math.random();
		let thumbs = {
			first_server: itm.first_server,
			last_server: itm.last_server,
			first_thumb: itm.first_thumb,
			last_thumb: itm.last_thumb
		}

		return (
			<TVPoster
				relevancy={itm.relevancy}
				count={itm.count}
				showTitle={itm._id.vid_grandparent_title}
				showSummary={itm.vid_summary}
				showStudio={itm.vid_studio}
				showYear={itm.vid_year}
				showRating={itm.vid_content_rating}
				vidArt={itm.vid_art}
				vidTheme={itm.vid_theme}
				fileDetails={thumbs}
				key={key}
			/>
		);
	}

	getMessageScreen() {
		if (this.state.firstload) {
			return <div className='vid-list-msg text-center'>Welcome to PlexWeb</div>;
		} else {
			return this.getLoadingScreen();
		}
	}

	getLoadingScreen() {
		if (this.state.firstload) {
			this.getMessageScreen();
		} else {
			let loader = null;
			(!this.state.user || this.state.user.safeSearch)
				? (loader = (
						<div className='vid-list-msg text-center'>
							I'm still looking aniki! uwu...
							<div className='loader' />
						</div>
				  ))
				: (loader = (
						<div className='vid-list-msg text-center'>
							HOLD YER HORSES CUNT I'M LOOKING...
							<div className='loader' />
						</div>
				  ));
			return loader;
		}
	}

	getRelevancy(a, b) {
		if (a.length === 0) return b.length;
		if (b.length === 0) return a.length;

		var matrix = [];

		// increment along the first column of each row
		var i;
		for (i = 0; i <= b.length; i++) {
			matrix[i] = [i];
		}

		// increment each column in the first row
		var j;
		for (j = 0; j <= a.length; j++) {
			matrix[0][j] = j;
		}

		// Fill in the rest of the matrix
		for (i = 1; i <= b.length; i++) {
			for (j = 1; j <= a.length; j++) {
				if (b.charAt(i - 1) === a.charAt(j - 1)) {
					matrix[i][j] = matrix[i - 1][j - 1];
				} else {
					matrix[i][j] = Math.min(
						matrix[i - 1][j - 1] + 1, // substitution
						Math.min(
							matrix[i][j - 1] + 1, // insertion
							matrix[i - 1][j] + 1,
						),
					); // deletion
				}
			}
		}

		//return (a.length * 100) / (a.length - matrix[b.length][a.length]);
		return ((a.length - matrix[b.length][a.length]) * 100) / a.length;
	}

	getResultsCount() {
		let results = null;

		this.state.user.safeSearch
			? (results = document.querySelectorAll('.vid-item:not(.nsfw)').length)
			: (results = document.querySelectorAll('.vid-item').length);

		return results;
	}

	getVideoPosters() {
		return (
			<div className='video-results-container'>
				{this.state && this.state.items.length < 1 ? (
					this.getMessageScreen()
				) : (
					<div className='tv-list'>
						{this.state && this.state.items.map(item => this.renderItem(item, this.state.nsfw_list))}
						{/* <div className="vis-indicator" /> */}
					</div>
				)}
			</div>
		);
	}

	render() {
		return (
			<div>
				<div className='searchbar-container'>
					<form onSubmit={this.handleSubmit}>
						<input
							type='text'
							name='search'
							className='searchbar'
							placeholder='TV Search (South Park)'
							disabled={this.state.isLoading ? 'disabled' : ''}
						/>
					</form>
				</div>
				<div className='results-container'>
					{this.state !== null ? (
						<div className='rc'>
							<div className='results-count'>
								Results (
								{this.state.isLoading ? this.getResultsCount() : this.getResultsCount()})
							</div>
							<div className='results-filter'>
								<VideoFilter searchType='TV' />
							</div>
						</div>
					) : null}
				</div>

				{this.state.isLoading ? this.getLoadingScreen() : this.getVideoPosters()}				
			</div>
		);
	}
}

export default withContext(TVListings);
