import React, { Component } from 'react';
import { withContext } from './../../Persistant/AppContext';

class Settings extends Component {
	handleSaveSettings() {
		let ev = new Event('saveSettings');
		document.dispatchEvent(ev);
	}

	render() {
		return (
			<div style={{ width: '75%', margin: '0 auto' }}>
				<h3>Settings</h3>
				<div className="settings-container">
					<div className="settings-titles col-5">
						<div className="row settings-group-title">Aria2 RPC Config</div>
						<div className="row settings-itm">Aria Secret Token</div>
						<div className="row settings-itm">Aria URI</div>
						<div className="row settings-itm">Get Aria</div>
					</div>
					<div className="settings-values col-7">
						<div className="row settings-group-title" />
						<div className="row settings-itm">
							<input
								id="settings-aria-secret"
								placeholder="mySecret"
								defaultValue={
									localStorage &&
									localStorage['aria-settings'] &&
									JSON.parse(localStorage['aria-settings']).secret
								}
								//                            onChange={() => this.handleSaveSettings()}
							/>
						</div>
						<div className="row settings-itm">
							<input
								id="settings-aria-uri"
								placeholder="http://localhost:6800/rpc"
								defaultValue={
									localStorage &&
									localStorage['aria-settings'] &&
									JSON.parse(localStorage['aria-settings']).uri
								}
								//                            onChange={() => this.handleSaveSettings()}
							/>
						</div>
						<div className="row settings-itm" style={{ lineHeight: 2, margin: '10px 5px 5px 5px' }}>
							<a
								href="https://hub.docker.com/r/binaryoutlook/aria2-with-webui"
								rel="noopener noreferrer"
								target="_blank"
							>
								aria2-with-webui (Docker Image)
							</a>
							<br />
							<a
								href="https://aria2.github.io/manual/en/html/aria2c.html#rpc-options"
								rel="noopener noreferrer"
								target="_blank"
							>
								Manual Aria2 RPC
							</a>
							<br />
						</div>
					</div>
				</div>
				<div className="settings-save-container">
					<div className="col-12 settings-submit">
						<button onClick={() => this.handleSaveSettings()}>
							<i className="fa fa-save" />Save
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default withContext(Settings);
