import React, { Component } from 'react';

class LargeOverlay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			styleHide: {
				visibility: 'hidden'
			},
			styleShow: {
				visibility: 'visible'
			},
			animations: {
				show: 'fadeInUp',
				hide: 'fadeOutDown',
				allowNext: true
			}
		};
		document.addEventListener(
			'showItemOverlay',
			function(e) {
				this.handleShowOverlayEvents(e);
			}.bind(this)
		);
		document.addEventListener(
			'closeItemOverlay',
			function(e) {
				this.handleHideOverlayEvents(e);
			}.bind(this)
		);
	}

	componentDidMount() {}

	handleShowOverlayEvents(e) {
		this.setState({
			isLoading: true,
			showOverlay: true,
			content: e.detail.content,
			bgImg: {
				backgroundImage: 'url(' + e.detail.bgImg + ')',
				backgroundColor: '#0F171E',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundAttachment: 'fixed'
			}
		});

		const overlay = document.querySelector('#overlay');
		overlay.classList.add('overlay-show');
		this._setAnimation('#overlay', this.state.animations.show, this.state.animations.hide);
	}

	handleHideOverlayEvents(e) {
		try {
			const overlay = document.querySelector('#overlay');
			let isHidden = overlay.classList;

			if (isHidden.contains('fadeInUp')) {
				this._setAnimation('#overlay', this.state.animations.hide, this.state.animations.show);
				function handleOverlayAnimationEnd() {
					node.removeEventListener('animationend', handleOverlayAnimationEnd);
					overlay.classList.remove('overlay-show');
				}
				const node = document.querySelector('#overlay');
				node.addEventListener('animationend', handleOverlayAnimationEnd);
				this.setState({
					isLoading: true,
					showOverlay: false,
					content: '<div></div>'
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	_setAnimation(ele, newAni, oldAni) {
		const node = document.querySelector(ele);
		node.removeAttribute('hidden');
		node.setAttribute('style', '{animation-delay: .1s;}');
		node.classList.remove('animated', oldAni);
		node.classList.add('animated', newAni);
	}

	_getStyle(){
		let style = {
			animationDelay: '.1s',
			backgroundImage: function(){
				return this.state.bgImg !== 'url()' ? this.state.bgImg : null;
			},
			backgroundPosition: 'center !important',
			backgroundRepeat: 'no-repeat !important',
			backgroundSize: 'cover !important',
			filter: 'grayscale(100%)'
		}
		return style;
	}

	render() {
		return (
			<div id="overlay" className="overlay animated fadeOutDown" style={this._getStyle()}>
				{<div className="overlay-bg" />}
				{this.state.content ? this.state.content : <div />}
			</div>
		);
	}
}

export default LargeOverlay;
