import React, { Component } from 'react';
import Settings from '../Components/Settings/Settings';

class ShowSettings extends Component {

    constructor(props){
        super(props)
        console.log('ShowSettings')
    }

    render() {
        return (
            <div>
                <Settings />
            </div>
        )
    }

}

export default ShowSettings;