import React, { Component } from 'react';
import axios from 'axios';
axios.defaults.withCredentials = true
const authAxios = axios.create();

authAxios.interceptors.request.use((config) => {
	return config;
});

const AppContext = React.createContext();

export class AppContextProvider extends Component {
	constructor() {
		super();
		this.state = {
			user: JSON.parse(localStorage.getItem('user')) || {},
			debug: true,
			loggedIn: this.checkToken()
		};
	}

	componentDidMount() {}

	CreateUser = (userInfo) => {
		return authAxios.post('/auth/CreateUser', userInfo).then((response) => {
			const { user, token } = response.data;
			localStorage.setItem('user', JSON.stringify(user));
			this.setState({
				user
			});
			return response;
		});
	};

	checkToken = async () => {
		let result;
		if (this.state && this.state.loggedIn && this.state.loggedDate > Date.now())
			return true;

		try {
			result = await authAxios.post('/auth/checktoken', {})
			if (result.status === 200) {
				this.setState({loggedIn: true, loggedDate: Date.now() + 3600000})
    			return true;
			}
			if (this.state.loggedIn)
				this.setState({loggedIn: false})
		} catch {
			if (this.state.loggedIn)
				this.setState({loggedIn: false})
		}
		return false;
	}

	unauthed = () => {
		this.setState({loggedIn: false})
	}

	checkTokenSync = () => {
		let returnValue = this.checkToken().then(
			async (value) => await Promise.all([value]).then(
				async result => await result[0]
			)
		).then(value => value);
		return returnValue;
	}

	login = (credentials) => {
		return authAxios.post('/auth/login', credentials).then((response) => {
			const { token, user } = response.data;
			localStorage.setItem('user', JSON.stringify(user));
			this.setState({
				user,
				loggedIn: true, 
				loggedDate: Date.now() + 3600000
			});
			return response;
		});
	};

	logout = () => {
		localStorage.removeItem('user');
		this.setState({
			user: null,
			loggedIn: false, 
			loggedDate: null
		});
	};

	debug = () => {
		this.setState({
			debug: true
		});
	};

	render() {
		return (
			<AppContext.Provider
				value={{
					CreateUser: this.CreateUser,
					login: this.login,
					logout: this.logout,
					unauthed: this.unauthed,
					debug: this.debug,
					checkToken: this.checkToken,
					checkTokenSync: this.checkTokenSync,
					...this.state
				}}
			>
				{this.props.children}
			</AppContext.Provider>
		);
	}
}

export const withContext = (Component) => {
	return (props) => {
		return (
			<AppContext.Consumer>
				{(globalState) => {
					return <Component {...globalState} {...props} />;
				}}
			</AppContext.Consumer>
		);
	};
};
