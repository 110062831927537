import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../Auth/ProtectedRoute';
import { withContext } from '../Persistant/AppContext';
import { Layout, Header, Navigation, Drawer, Content } from 'react-mdl';
import 'react-mdl/extra/material.js';
// Routes
import TVListings from '../Components/TVListings/TVListings';
import Search from '../Components/Search/Search';
import ShowMovies from './ShowMovies';
import ShowSettings from './ShowSettings';
// Images
import Logo from './../Assets/Images/logo-light.png';

const Main = () => (
	<Router>
		<Layout className="all-content">
			<Header
				className="header-color"
				title={
					<Link style={{ textDecoration: 'none', color: 'white' }} to="/">
						<img src={Logo} alt="logo" />
					</Link>
				}
				scroll
			>
				<Navigation>
					<Link to="/" className="top-link" active="true">
						Movie Listings
					</Link>
					<Link to="/tv" className="top-link">
						TV Listings
					</Link>
					<Link to="/search" className="top-link">
						Advanced Search
					</Link>
					<Link to="/settings" className="top-link">
						<i className="fas fa-cog" />
					</Link>
				</Navigation>
			</Header>
			<Drawer
				title={
					<Link style={{ textDecoration: 'none', color: 'black' }} to="/">
						PlexWeb
					</Link>
				}
			>
				<Navigation>
					<Link to="/" className="side-link">
						Movie Listings
					</Link>
					<Link to="/tv" className="side-link">
						TV Listings
					</Link>
					<Link to="/search" className="side-link">
						Advanced Search
					</Link>
					<Link to="/settings" className="top-link">
						<i className="fas fa-cog" />
					</Link>
				</Navigation>
			</Drawer>
			<Content>
				<div className="container-content">
					<Switch>
						<ProtectedRoute exact path="/" component={ShowMovies} />
						<ProtectedRoute path="/tv" component={TVListings} />
						<ProtectedRoute path="/search" component={Search} />
						<ProtectedRoute path="/settings" component={ShowSettings} />
					</Switch>
				</div>
			</Content>
		</Layout>
	</Router>
);

export default withContext(Main);
