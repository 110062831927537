import React, { Component } from 'react';
import axios from 'axios';
import { withContext } from '../../../Persistant/AppContext';
import TVPoster from '../ListingHelpers/tpl_tv_poster';
import noPoster from '../../../Assets/Images/no-poster.jpg';
import VideoPlayer from '../ListingHelpers/tpl_player_naked';
import { downloadFile, getFileNameFromPath, uuidv4 } from '../../../App.Events';
import xmlrpc from 'xmlrpc';
axios.defaults.withCredentials = true

class TVEpisodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            items: [],
            season_title: 'Test',
            current_season: -1,
            season_nums: [],
            resolutions: {
                '16k': false,
                '8k': false,
                '4k': false,
                '2.7k': false,
                '2k': false,
                '1080': false,
                '720': false,
                'sd': false,
                '480': false,
                '576': false,
                'null': false,
            },
            qualityOpts: null,
            //vidFile: 'http://distribution.bbb3d.renderfarming.net/video/mp4/bbb_sunflower_2160p_60fps_normal.mp4',
            vidFile: '',
            vidEpList: null,
            vidEPListIndex: 0,
            ep_list: [],
            all_ep_list: [],
            get_ep_list: false,
            newTitle: null,
            initialPlay: true,
            changedStyle: false
        };
    }

    componentDidMount() {
        this.setState({
            isLoading: false,
            items: [],
        });

        this.getSeasonQuality();                
    }
    
    componentDidUpdate(){
        if (this.state.get_ep_list === true){
            this.setState({
                get_ep_list: false
            })
            this.getShowDetails();
        }
    }

    getSeasonQuality = async e => {
        let seasons = [];
        let resolutions = {
            '16k': false,
            '8k': false,
            '4k': false,
            '2.7k': false,
            '2k': false,
            '1080': false,
            '720': false,
            'sd': false,
            '480': false,
            '576': false,
            'null': false,
        };
        this.setState({
            isLoading: true
        });
        const apiEndpoint = '/api';
        const seasonquality_url = `${apiEndpoint}/seasonquality/${this.props.showTitle}`;
        let seasonquality
        try {
            seasonquality = await axios(seasonquality_url);
            if (seasonquality.status == 401)
                this.props.unauthed();
        } catch (ex) {
            console.log(ex)
            this.props.unauthed();
        }
        //console.log('seasonquality:', seasonquality);

        seasonquality.data.forEach(itm => {
            if (!seasons.includes(itm._id)){
                seasons.push(itm._id)                
            }            
            itm.vid_resolution.forEach(res => {
                resolutions[res] = true;
            })            
        })

        let opts = [];
        let p = resolutions;
        //console.log(resolutions);
        Object.keys(p).forEach(function (k) {
            if (p[k]) {
                opts.push(<option value={k}>{k}</option>);
            }
        })

        seasons.sort((a, b) => a - b);
        this.setState({
            season_nums: seasons,
            resolutions: resolutions,
            qualityOpts: opts
        });

        console.log('State Check', this.state);
        this.getSeasonNums();
    }

    getSeasonNums = async e => {
        // Query to get list of available seasons for this show
        this.setState({
            isLoading: true
        });
        const apiEndpoint = '/api';
        const season_url = `${apiEndpoint}/season_list/${this.props.showTitle}`;
        let seasonDetails
        try {
        seasonDetails = await axios(season_url);
            if (seasonDetails.status == 401)
               this.props.unauthed();
        } catch (ex) {
            console.log(ex)
            this.props.unauthed();
        }
        //console.log('[START of SEASON DATA]')
        //console.log('Season List', seasonDetails);
        let season_data = [];
        seasonDetails.data.forEach(function(s){
            season_data.push(s._id.season)
        });

        season_data.sort(
            //Sort items by season number.
            (a, b) => (b < a ? 1 : -1),
        );
        
        let starting_season = 0;
        season_data.forEach(function(i){
            if (i > 0 && starting_season === 0){
                starting_season = i;
            }
        });

        this.setState({
            isLoading: false,
            season_nums: season_data,
            current_season: starting_season
        });

        //console.log(`Season List [${this.state.current_season}]`, this.state.season_nums);
        this.getShowDetails();
    }

    getShowDetails = async e => {
        /*
           idea on how to speed up things...
           1.) Query to get a list of available seasons
            1a. Fill season select with values
            1b. get lowest season number, query for only those episodes
            1c. fill episode list with lowest value season items
            1d. onChange season select box query for selected season items
        */

        // Reset Episode Lists
        this.setState({
            all_ep_list: [],
            ep_list: [],
            isLoading: true
        });

        const apiEndpoint = '/api';
        const show_url = `${apiEndpoint}/newTVSearch/${this.props.showTitle}/${this.state.current_season}`;
        let showDetails
        try {
            showDetails = await axios(show_url);
            if (showDetails.status == 401)
               this.props.unauthed();
        } catch (ex) {
            console.log(ex)
            this.props.unauthed();
        }
        let show_data = {
            '16k': [],
            '8k': [],
            '4k': [],
            '2.7k': [],
            '2k': [],
            '1080': [],
            '720': [],
            'sd': [],
            '480': [],
            '576': [],
            'null': [],
        };
        
        showDetails.data.forEach(function(s){
            show_data[s.vid_resolution].push(s);
        })
        

        for (let p in show_data){
            show_data[p].sort(
                (a, b) => (parseInt(b.vid_episode_num) < parseInt(a.vid_episode_num) ? 1 : -1)
            );
        }

        let selected_quality = document.querySelector('.season-quality-select');

        //console.log("EP_LIST", show_data[selected_quality.value]);
        this.setState({
            isLoading: false,
            all_ep_list: show_data,
            current_quality: selected_quality.value,
            ep_list: show_data[selected_quality.value],
        });        
    }

    setSeasonTitle(){
        let season_select = document.querySelector('.season-select');
        this.setState({
            season_title: season_select.value,
        });
    }

    handleSeasonChange = async e => {
        // handles when user selects a specific season
        let season_num = document.querySelector('.season-select');
        this.setState({
            current_season: season_num.value,
            get_ep_list: true
        });

        console.log('Season Choice: ', season_num.value);

        //this.getShowDetails();
    }

    handleChangeQuality = async e => {
        // handles when user selects a specific quality
        //.season-quality-select
        let selected_quality = document.querySelector('.season-quality-select');

        this.setState({ 
            current_quality: selected_quality.value,
            ep_list: this.state.all_ep_list[selected_quality.value]
        });
        
    }

    getBackgroundDiv() {
        let cssText = `//filter: blur(8px) grayscale(100%);
        //-webkit-filter: blur(8px) grayscale(100%);
        z-index:-99;
        position:absolute;
        width:100%;
        height:100%;
        background-attachment:fixed;
        //background-image: url(${this.props.vidArt});
        background-position:center !important;
        background-repeat:no-repeat !important;
        background-size:cover !important;
        height: 850px;         
        background: linear-gradient(to top right, rgb(0, 0, 0), rgba(3, 9, 14, 0.93), rgba(5, 18, 29, 0.87), rgba(8, 27, 43, 0.8), rgba(11, 36, 57, 0.73), rgba(13, 45, 72, 0.67), rgba(16, 55, 86, 0.6), rgba(18, 64, 101, 0.54), rgba(21, 73, 115, 0.47), rgba(24, 82, 129, 0.4), rgba(26, 91, 144, 0.34), rgba(29, 100, 158, 0.27)), url(${this.props.vidArt});
        background-size: cover; 
        box-shadow: 0px 10px 20px -5px rgba(0,0,0,.8);
        `;

        const overlay = document.querySelector('.overlay-bg');
        overlay.style.cssText = cssText;
    }

    getPosterStyle(){
        let style_options = [
            '153,51,51', // red
            '153,51,113', // dull purple
            '127,51,153', // bright purple
            '61,51,153', // violet
            '51,84,153', // powder blue
            '51,153,138', // sea green
            '51,153,72', // lime green
            '129,153,51', // lemon yellow
            '153,125,51', // orange
            '7,19,41', // dark blue
        ];

        let randChoice = Math.floor(Math.random() * (10 - 1) + 1);
        let style = {
            background: `linear-gradient(80deg, rgb(${style_options[randChoice]}) 10%, rgba(0, 0, 0, 0) 100%),
            url(${this.props.showPoster})`
        }

        this.setState({
            changedStyle: style
        })

    }
    callbackChildSources = childData => {
        this.setState({
            currentSource: childData
        });
    }

    handlePlayVideo = async e => {
        this.setState({
            vidEpList: e
        });
    }
    
    renderPlayer(){
        return this.state.isLoading ? (
            <div className="Loading" />
        ) : (
            <VideoPlayer
                key={Date.now()}
                vidEPList={this.state.vidEpList}
                vidParent={this.props.showTitle}
                parentCallback={this.callbackChildSources}
            />
        );
    }

    handleDownloadVideo(itm){
        let url = `http://${itm[0].server.s_address}:${itm[0].server.s_port}${itm[0].part_key}?X-Plex-Token=${itm[0].server.s_accessToken}&download=1`;
        downloadFile(url);
    }
    
    downloadURL = '';
	handleGenerateAriaLinks(itms, epNum) {
		//Release prior Object if exists
		if (this.downloadURL) {
			URL.revokeObjectURL(this.downloadURL);
		}
		// Get Download link
		let download = document.querySelector('.downloadList');
		//Generate aria recongnizable format for text downloads
		let out = '';
        itms.forEach(function(itm, i){
            let fileLocation = `http://${itm.server.s_address}:${itm.server.s_port}${itm.part_key}?X-Plex-Token=${itm.server.s_accessToken}&download=1\t`;
            out += fileLocation;   
        });
		//Create blob
		let file = new Blob([out], { type: 'text/plain;charset=utf-8' });
		//Generate new blob URL
		this.downloadURL = URL.createObjectURL(file);
		//Set addressable version of the blob to the download link
		download.setAttribute('download', `aria-${this.props.showTitle}-S${this.state.current_season}E${epNum}.plexaria`);
		download.setAttribute('href', this.downloadURL);
	}

	handleAriaRPC(itms) {
		if (localStorage.getItem('aria-settings')) {
			try {
				let ariaSettings = JSON.parse(localStorage.getItem('aria-settings'));
				let q = new URL(ariaSettings.uri);

				let client = xmlrpc.createClient({ host: q.hostname, port: q.port, path: q.pathname });
                let urls = [];
                
                itms.forEach(function(itm, i){
                    urls.push(`http://${itm.server.s_address}:${itm.server.s_port}${itm.part_key}?X-Plex-Token=${itm.server.s_accessToken}&download=1`,)
                })

				client.methodCall(
					'aria2.addUri',
					[
						`token:${ariaSettings.secret}`,
						urls,
						{ out: `${getFileNameFromPath(itms[0].part_file)[0]}` },
					],
					function(error, value) {
						if (error) alert(error);
						console.log(value);
					},
				);
			} catch (err) {
				alert("Issue connecting to your Aria RPC Server, please make sure it's running");
			}
		} else {
			alert('Please go setup your aria settings in the settings menu');
		}
	}

    padNumber(n){
        return parseInt(n) < 10 ? '0' + n : n;
    }

    getEpList(itm){
        //console.log('EP Item: ', itm);
        return(
            <li className="tv-ep">
                <div className="tv-ep-list-season">{`S${this.padNumber(itm.vid_season_num)}E${this.padNumber(itm.vid_episode_num)}`}</div>
                <div className="tv-ep-list-res">{this.state.current_quality}</div>
                <div className="tv-ep-list-name">{itm.vid_title}</div>
                <div className="tv-ep-list-count">{itm.items.length}</div>
                <div className="tv-ep-list-actions-itm">
                    <i className="fa fa-play" onClick={() => this.handlePlayVideo(itm.items)} title={`Watch Episode`} /> 
                    <i className="fa fa-download" onClick={() => this.handleGenerateAriaLinks(itm.items, itm.vid_episode_num) } title={`Browser Download\nAttempts to download first source\nUse Aria options for better results.`} /> 
                    <a
                        href='/#'
                        download={'aria-.txt'}
                        onClick={() => this.handleGenerateAriaLinks(itm.items, itm.vid_episode_num)}
                        style={{ textDecoration: 'none' }}
                        className='downloadList'>
                            <i className="fa fa-cogs" title={`Download Aria List`} />
                    </a>
                    <i className="fab fa-asymmetrik" onClick={() => this.handleAriaRPC(itm.items)} title={`Aria RPC client`} />
                </div>
            </li>
        );
   }

    renderSeasonNums(itm){
        let itmname = itm;
        let selected = '';
        if (parseInt(itm) === 0){
            itmname = `${itm} (specials)`;
        }

        if (itm === this.state.current_season){
            selected = 'selected';
        }

        return (<option value={itm} selected={selected} >Season {itmname}</option>);
    }

    getLoadingScreen(){
        return <div className='loader' />
    }

    getEpListLoader(){
        if (this.state.ep_list.length > 0){           
            this.startPlayer();
            return this.state.ep_list.map(itm => this.getEpList(itm));
        }else{
            return <li style={{color: 'red', fontWeight: 'bold'}}>No episodes available for this quality</li>
        }
    }

    startPlayer(){
        if (this.state.ep_list.length > 0 && this.state.initialPlay){
            this.handlePlayVideo(this.state.ep_list[0].items);
            this.setState({
                initialPlay: false
            })
        }
    }

    render(){
        return(
            <div>
                <div className="tv-ep-poster" style={!this.state.changedStyle ? this.getPosterStyle() : this.state.changedStyle }>
                    <div className="tv-show-details">
                        <div className="tv-show-name">
                            <span className="tv-name">{this.props.showTitle}</span>
                        </div>
                        <div className="tv-show-format">
                            {this.props.showStudio} | {this.props.showRating} | {this.props.showYear}
                        </div>
                        <div className="tv-show-summary">
                            {this.props.showSummary}
                        </div>
                    </div>
                    
                </div>
                <div className="tv-ep-details">
                    <div className="tv-ep-player">
                        {this.state.vidEpList ? this.renderPlayer() : null}
                    </div>
                    <div className="tv-ep-season-selector">
                        <div className="tv-season-num">
                            <select className="season-select" onChange={() => this.handleSeasonChange()}>
                                {this.state.season_nums.length > 0 ? this.state.season_nums.map(itm => this.renderSeasonNums(itm)) : null}
                            </select>
                        </div>
                        <div className="tv-season-quality">  
                            <select className="season-quality-select" onChange={() => this.handleChangeQuality()}>
                                {this.state.qualityOpts}
                            </select>
                        </div> 
                        <div className="tv-season-download">
                            <button type="button">
                                <i className="fab fa-asymmetrik" /> Download all: Season {this.state.season_title}
                            </button>
                        </div>                        
                    </div>
                    <div className="tv-ep-episode-selector">
                        <div className="tv-ep-list-titles">
                            <div className="tv-ep-list-season" style={{textAlign: 'center', marginLeft: '1rem'}}>EP</div>
                            <div className="tv-ep-list-res" style={{textAlign: 'center'}}>Res</div>
                            <div className="tv-ep-list-name" style={{ textAlign: 'center' }}>Title</div>
                            <div className="tv-ep-list-count" style={{ textAlign: 'center' }}>Count</div>
                            <div className="tv-ep-list-actions" style={{ textAlign: 'center', marginRight: '1rem' }}>Actions</div>
                        </div>
                        <ul className="tv-ep-list">
                            {this.state.isLoading ? this.getLoadingScreen() : this.getEpListLoader() }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default withContext(TVEpisodes);
