import React from 'react';
import ReactDom from 'react-dom';
import { getDuration, getSize, fireEvent } from './../../../App.Events';

export function VideoQuickInfo(newTitle, vidItem){
    const title = newTitle
    const fileTitle = vidItem.vid_title
    const year = vidItem.vid_year
    const resolution = vidItem.vid_resolution
    const audio = vidItem.vid_audioCodec
    const video = vidItem.vid_videoCodec

    ReactDom.render(
    <div id="hover-title" className="hover-title">
        <div className="quick-info-container">
            <div className="quick-info-close"><i className="fa fa-times-circle close-quick-info" onClick={()=>fireEvent('closeSmOverlay')}/></div>
            <div className="quick-info-header">{title}</div>
            <div className="quick-info-subheader"><small>{fileTitle}</small></div>
            <div className="quick-info-col">
                <div className="quick-info-row">
                    <div className="quick-info-title">Year:</div>
                    <div className="quick-info-data">{year}</div>
                </div>
                <div className="quick-info-row">
                    <div className="quick-info-title">Resolution:</div>
                    <div className="quick-info-data">{resolution}</div>
                </div>
            </div>
            <div className="quick-info-col">
                <div className="quick-info-row">
                    <div className="quick-info-title">Audio:</div>
                    <div className="quick-info-data">{audio}</div>
                </div>
                <div className="quick-info-row">
                    <div className="quick-info-title">Video:</div>
                    <div className="quick-info-data">{video}</div>
                </div>
            </div>
            <div className="quick-info-col">
                <div className="quick-info-row">
                    <div className="quick-info-title">Duration:</div>
                    <div className="quick-info-data">{getDuration(vidItem.vid_duration)}</div>
                </div>
                <div className="quick-info-row">
                    <div className="quick-info-title">File Size:</div>
                    <div className="quick-info-data">{getSize(vidItem.part_size)}</div>
                </div>
            </div>
        </div>
    </div>
    , document.getElementById('sm-overlay')
    )
    fireEvent('showSmOverlay')
}