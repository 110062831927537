import React, { Component } from 'react';
import VideoPlayer from '../ListingHelpers/tpl_video_player';
import { uuidv4 } from '../../../App.Events';

class VideoPlayerContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contextSource: {
				newTitle: this.props.contextSource.newTitle,
				oldTitle: this.props.contextSource.oldTitle,
				vidLocation: this.props.contextSource.vidLocation
			},
			isLoading: true
		};
		this.sourceCnt = 0;
	}

	componentWillUpdate() {
		this.sourceCnt = 0;
	}

	componentDidMount() {
		this.setState({
			isLoading: false
		});
	}

	renderPlayer() {
		return this.state.isLoading ? (
			<div className="Loading" />
		) : (
			<VideoPlayer
				key={uuidv4()}
				new_title={this.state.contextSource.newTitle}
				vid_title={this.state.contextSource.oldTitle}
				vid_file_location={this.state.contextSource.vidLocation}
			/>
		);
	}

	handlePlayNewItem(sourceNum) {
		let vidFileLocation =
			this.props.contextSource.sources[sourceNum].library.resource.Connection[0].uri +
			this.props.contextSource.sources[sourceNum].part_key +
			'?X-Plex-Token=' +
			this.props.contextSource.sources[sourceNum].library.resource.accessToken +
			'&download=1';
		this.setState({
			contextSource: {
				newTitle: this.props.contextSource.sources[sourceNum].vid_title,
				oldTitle: this.props.contextSource.sources[sourceNum].vid_title,
				vidLocation: vidFileLocation
			}
		});
	}

	renderSourceList() {
		return (
			<div className="sources-container" key={uuidv4()}>
				<div className="sourceRow">
					<div className="sourceTitle col-1">#</div>
					<div className="sourceTitle col-1">Play Source</div>
					<div className="sourceTitle col-3">Server Name</div>
					<div className="sourceTitle col-1">Server ID</div>
					<div className="sourceTitle col-2">Server IP</div>
					<div className="sourceTitle col-2">AccessToken</div>
					<div className="sourceTitle col-3">Library Name</div>
					<div className="sourceTitle col-1">Library ID</div>
				</div>
				<div className="info-seperator" />
				{this.props.contextSource.sources.map((item) => this.renderSourceItem(item))}
			</div>
		);
	}

	renderSourceItem(itm) {
		this.sourceCnt++;
		let itmNum = this.sourceCnt - 1;
		return (
			<div className="sourceRow" key={uuidv4()}>
				<div className="sourceNum col-1">{this.sourceCnt}</div>
				<div className="sourceActions col-1">
					<i onClick={() => this.handlePlayNewItem(itmNum)} className="fa fa-play" />
				</div>
				<div className="sourceName col-3">{itm.library.resource.name}</div>
				<div className="soueceServerId col-1">{itm.library.resource.id}</div>
				<div className="sourceIP col-2">{itm.library.resource.Connection.filter(c=> c && c.local != '1')[0].address}</div>
				<div className="sourceToken col-2">
					<a href={`/token/${itm.library.resource.accessToken}`}>{itm.library.resource.accessToken}</a>
				</div>
				<div className="sourceLibrary col-3">{itm.library.lib_title}</div>
				<div className="sourceLibrayId col-1">{itm.library.id}</div>
			</div>
		);
	}

	render() {
		return (
			<div>
				{this.renderPlayer()}
				{this.renderSourceList()}
			</div>
		);
	}
}

export default VideoPlayerContainer;
