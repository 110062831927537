import React, { Component } from 'react';
import axios from 'axios';
import { withContext } from '../../../Persistant/AppContext';
import TVPoster from '../ListingHelpers/tpl_tv_poster';
import noPoster from '../../../Assets/Images/no-poster.jpg';
axios.defaults.withCredentials = true


class TVEpisodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            items: [],
        };
        console.log('GenOver', this.props);
    }

    componentDidMount() {
        this.setState({
            isLoading: false,
            items: [],
        });
    }

    handleImgError(e) {
        e.target.src = noPoster;
    }

    handleSubmit = async e => {
        window.stop();
        e.preventDefault();
        this.firstload = false;
        this.state.isLoading = true;

        const apiEndpoint = '/api';
        const nsfw_url = `${apiEndpoint}/nsfw/`;
        let nsfw_list
        try {
            nsfw_list = await axios(nsfw_url);
            if (nsfw_list.status == 401)
                this.props.unauthed();
        } catch (ex) {
            console.log(ex)
            this.props.unauthed();
        }
        let query = document.querySelector('.searchbar').value;

        //AutoQuote searches.
        query = query.match(/"/)
            ? query
            : query
                .split(' ')
                .map(function (item) {
                    return item.match(/^-/) ? item : `"${item}"`;
                })
                .join(' ');

        const api_url = `${apiEndpoint}/shows/${query}?page=0`;

        this.setState({
            isLoading: true,
        });
        let result
        try {
            result = await axios(api_url);
            if (result.status == 401)
                this.props.unauthed();
        } catch (ex) {
            console.log(ex)
            this.props.unauthed();
        }
        console.log(result);

        this.setState({
            isLoading: false,
            items: result.data.sort(
                //Sort items by vid count desc.
                (a, b) => b.items.length - a.items.length,
            ),
            nsfw_list: nsfw_list,
        });
    };

    renderItem(itm, nsfw_list) {

        let isActive = itm[3].items.find(item => {
            return item.server.isActive;
        });

        if (isActive) {
            return <TVPoster fileDetails={itm.items} />;
        }
    }

    getBackgroundDiv(){
        let cssText = `//filter: blur(8px) grayscale(100%);
        //-webkit-filter: blur(8px) grayscale(100%);
        z-index:-99;
        position:absolute;
        width:100%;
        height:100%;
        background-attachment:fixed;
        //background-image: url(${this.props.vidArt});
        background-position:center !important;
        background-repeat:no-repeat !important;
        background-size:cover !important;
        height: 850px;         
        background: linear-gradient(to top right, rgb(0, 0, 0), rgba(3, 9, 14, 0.93), rgba(5, 18, 29, 0.87), rgba(8, 27, 43, 0.8), rgba(11, 36, 57, 0.73), rgba(13, 45, 72, 0.67), rgba(16, 55, 86, 0.6), rgba(18, 64, 101, 0.54), rgba(21, 73, 115, 0.47), rgba(24, 82, 129, 0.4), rgba(26, 91, 144, 0.34), rgba(29, 100, 158, 0.27)), url(${this.props.vidArt});
        background-size: cover; 
        box-shadow: 0px 10px 20px -5px rgba(0,0,0,.8);
        `;

        const overlay = document.querySelector('.overlay-bg');
        overlay.style.cssText = cssText;
    }

    render() {
        return (
            <div>
                <div className='tv-results-container'>
                    {this.getBackgroundDiv()}
                    <div className='box tv-poster-container grid2'>
                        <div className='box tv-poster'>
                            <div className='row'>
                                <img 
                                    className='tv-poster-img responsive' 
                                    src={this.props.showPoster} 
                                    alt='tv poster' 
                                    onError={this.handleImgError.bind(this)}
                                />
                            </div>
                            <div className='row tv-poster-title text-white'>{this.props.showTitle}</div>
                        </div>
                        <div className='box tv-summary'>
                            <h5> Summary </h5>
                            <p className='text-white'>
                                This is a summary of a tv show(overall summary not per episode).I 'm bad at
								writing placeholder text so i 'll just keep rambling on now to extend this text
								and make it as big as possible to account for overly lengthy descriptions.
							</p>
                        </div>
                    </div>
                    <div className='box tv-result-itm'>
                        <div className='tv-season-group'>
                            <div className='box tv-season-title'>
                                <select className='tv-season-selector'>
                                    <option className='optSeason' value='01'>
                                        Season 1
									</option>
                                    <option className='optSeason' value='02'>
                                        Season 2
									</option>
                                    <option className='optSeason' value='03'>
                                        Season 3
									</option>
                                    <option className='optSeason' value='04'>
                                        Season 4
									</option>
                                    <option className='optSeason' value='05'>
                                        Season 5
									</option>
                                </select>
                            </div>
                            <div className='box tv-season-eps grid'>
                                <div className='tv-ep-itm'>
                                    <div>
                                        <img src={noPoster} className='responsive' alt='ep1' />
                                        <div className='tv-ep-details'>
                                            <div className='tv-ep-num'> 01 </div>
                                            <div className='tv-ep-img-count'> 256 </div>
                                        </div>
                                        <div className='tv-ep-itm-details'> Episode Name </div>
                                    </div>
                                </div>
                                <div className='video-list'>
                                    {this.state &&
                                        this.state.items.map(item =>
                                            this.renderItem(item, this.state.nsfw_list),
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withContext(TVEpisodes);
