import React, { Component } from 'react';
import { withContext } from './../Persistant/AppContext';

// Stylesheets and Images
import './../Assets/sass/login.scss';
import logoLarge from './../Assets/Images/logo-large.png';

class LoginForm extends Component {
	constructor() {
		super();
		this.state = {
			username: '',
			password: '',
			errorMessage: ''
		};
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	clearInputs = () => {
		this.setState({
			username: '',
			password: '',
			errorMessage: ''
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.login(this.state).then(() => this.props.history.push('/')).catch((err) => {
			this.setState({ errorMessage: err.response.data.message });
		});
	};

	render() {
		return (
			<div className="parent">
				<div className="form-wrapper">
					<form onSubmit={this.handleSubmit}>
						<div className="login-container">
							<div className="col-12 text-center">
								<img className="responsive" src={logoLarge} alt="Logo" />
							</div>
							<div className="col-12 login-field">
								<input
									onChange={this.handleChange}
									value={this.state.username}
									name="username"
									type="text"
									placeholder="username"
								/>
							</div>
							<div className="col-12 login-field">
								<input
									onChange={this.handleChange}
									value={this.state.password}
									name="password"
									type="password"
									placeholder="password"
								/>
							</div>
							<div className="col-12 login-field">
								<button type="submit">Submit</button>
							</div>
							<div className="col-12 login-err text-center">
								{this.state.errorMessage && <p>{this.state.errorMessage}</p>}
							</div>
						</div>
					</form>
				</div>
				<div className="stars" />
				<div className="twinkling" />
				<div className="clouds" />
			</div>
		);
	}
}

export default withContext(LoginForm);
