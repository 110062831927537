import React, { useState } from "react"
import { Route, Redirect } from "react-router-dom";
import { withContext } from "./../Persistant/AppContext"
import axios from "axios"

axios.defaults.withContext = true;

function ProtectedRoute(props) {
    
    if (!props.loggedIn || Date.now() < props.loggedDate)
        console.log(`Auth State: ${props.checkTokenSync()}`);

    const { component: Component, ...rest } = props;
    
    return (
        !props.loggedIn ?
            <Redirect to="/login" /> :
            <Route {...rest} component={Component} /> 
    )
}

export default withContext(ProtectedRoute);