import React, { Component } from 'react';

class Search extends Component {
    render(){
        return(
            <h1>Search Placeholder</h1>
        )
    }
}

export default Search;