import React, { Component } from 'react';
import { withContext } from '../../../Persistant/AppContext';

class VideoFilter extends Component {
	handleShowOverlay() {}

	config = {
		Open: false,
	};

	handleFilterClose() {
		const filterEle = document.querySelector('.tooltip .bottom');
		filterEle.classList.remove('tooltip-show-bottom');
		this.config.Open = false;
	}
	handleFilterShow() {
		const filterEle = document.querySelector('.tooltip .bottom');
		filterEle.classList.add('tooltip-show-bottom');
		this.config.Open = true;
	}
	toggleFilterDisplay() {
		if (!this.config.Open) {
			this.handleFilterShow();
		} else {
			this.handleFilterClose();
		}
	}

	componentDidMount() {
		var nsfw_filter = document.querySelector('[id=d]');
		let user = JSON.parse(localStorage.getItem('user'));
		const safeSearch = (!user || user.safeSearch)
		nsfw_filter.checked = safeSearch;
		this.handleNSFWSearchBar(safeSearch);
		if (this.props.debug) console.log('NSFW Filter: ' + safeSearch);
	}

	handleNSFWSearchBar(nsfw_flag) {
		let searchbar = document.querySelector('input.searchbar');
		if (nsfw_flag) {
			searchbar.classList.remove('searchbar-nsfw');
			searchbar.setAttribute('placeholder', `${this.props.searchType} Search`);
		} else {
			searchbar.classList.add('searchbar-nsfw');
			searchbar.setAttribute('placeholder', `(SafeSearch: Off) ${this.props.searchType} Search`);
		}
	}

	bindNSFWStateChanges() {
		let nsfw_filter = document.querySelector('[id=d]');
		nsfw_filter.addEventListener(
			'change',
			function(e) {
				let checkbox = e.target;
				let nsfw_itms = document.querySelectorAll('.nsfw');
				if (checkbox.checked === true) {
					checkbox.checked = false;
					for (var i = 0; i < nsfw_itms.length; i++) {
						nsfw_itms[i].classList.remove('hidden');
					}
					this.handleNSFWSearchBar(false);
				} else {
					checkbox.checked = true;
					for (var x = 0; x < nsfw_itms.length; x++) {
						nsfw_itms[x].classList.add('hidden');
					}
					this.handleNSFWSearchBar(true);
				}
				let user = JSON.parse(localStorage.getItem('user'));
				user.safeSearch = checkbox.checked;
				window.localStorage.setItem('user', JSON.stringify(user));
			}.bind(this),
		);
	}

	handleResFilter(res) {
		let eleFilterSelector = Array.prototype.slice.apply(document.querySelectorAll('[vidres]'));

		eleFilterSelector.forEach(ele => {
			let resType = ele.getAttribute('vidres');
			resType === res ? ele.classList.remove('vid-filter-res-hide') : ele.classList.add('vid-filter-res-hide');
		});
	}

	handleTitleFilter_old() {
		let title = document.querySelector('#filterFindTitle').value.toLowerCase();
		let eleFilterSelector = Array.prototype.slice.apply(document.querySelectorAll('[vidtitle]'));
		eleFilterSelector.forEach(ele => {
			let vidTitle = ele.getAttribute('vidtitle').toLowerCase();
			title !== ''
				? title.includes('~')
					? vidTitle.includes(title.replace('~', ''))
						? ele.classList.remove('vid-filter-title-hide')
						: ele.classList.add('vid-filter-title-hide')
					: vidTitle === title
					? ele.classList.remove('vid-filter-title-hide')
					: ele.classList.add('vid-filter-title-hide')
				: ele.classList.remove('vid-filter-title-hide');
		});
	}

	handleTitleFilter = () => {
		let title = document.querySelector('#filterFindTitle').value.toLowerCase();
		let eleFilterSelector = Array.prototype.slice.apply(document.querySelectorAll('[vidtitle]'));
		eleFilterSelector.forEach(ele => {
			let vidTitle = ele.getAttribute('vidtitle').toLowerCase();

			if (title !== '') {
				title.includes('~')
					? vidTitle.includes(title.replace('~', ''))
						? ele.classList.remove('vid-filter-title-hide')
						: ele.classList.add('vid-filter-title-hide')
					: vidTitle === title
					? ele.classList.remove('vid-filter-title-hide')
					: ele.classList.add('vid-filter-title-hide');
			} else {
				ele.classList.remove('vid-filter-title-hide');
			}
		});
	};

	handleYearFilter() {
		let year = document.querySelector('#filterFindYear').value.toLowerCase();
		let eleFilterSelector = Array.prototype.slice.apply(document.querySelectorAll('[vidyear]'));
		eleFilterSelector.forEach(ele => {
			let vidYear = ele.getAttribute('vidyear');
			vidYear === year
				? ele.classList.remove('vid-filter-year-hide')
				: ele.classList.add('vid-filter-year-hide');
		});
	}

	render() {
		return (
			<div className='tooltip'>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 560 560'
					id='video-filter'
					aria-hidden='true'
					className='video-filter'
					onClick={() => this.toggleFilterDisplay()}>
					<path d='M126 213.205A69.97 69.97 0 0 0 105 210a69.97 69.97 0 0 0-21 3.205V56h42v157.205zm0 133.59V504H84V346.795A69.97 69.97 0 0 0 105 350a69.97 69.97 0 0 0 21-3.205zm175-49.59A69.97 69.97 0 0 0 280 294a69.97 69.97 0 0 0-21 3.205V56h42v241.205zm0 133.59V504h-42v-73.205A69.97 69.97 0 0 0 280 434a69.97 69.97 0 0 0 21-3.205zm175-301.59A69.97 69.97 0 0 0 455 126a69.97 69.97 0 0 0-21 3.205V56h42v73.205zm0 133.59V504h-42V262.795A69.97 69.97 0 0 0 455 266a69.97 69.97 0 0 0 21-3.205zM280 420c-30.928 0-56-25.072-56-56s25.072-56 56-56 56 25.072 56 56-25.072 56-56 56zm175-168c-30.928 0-56-25.072-56-56s25.072-56 56-56 56 25.072 56 56-25.072 56-56 56zm-350 84c-30.928 0-56-25.072-56-56s25.072-56 56-56 56 25.072 56 56-25.072 56-56 56z' />
				</svg>
				<div className='bottom filter-options'>
					<h3>Filter Options</h3>
					<div className='filter-controls'>
						<div className='row'>
							<div className='filter filterLabel-input'>Safe-Search:</div>
							<div className='filter filterInput'>
								<div className='can-toggle demo-rebrand-1'>
									<input
										id='d'
										name='nsfw_filter_select'
										type='checkbox'
										onClick={this.bindNSFWStateChanges.bind(this)}
									/>
									<label
										htmlFor='d'
										title='(Red) = Shows XXX | (Green) = Safe for Work&#013;(F2 = Toggle NSFW Hotkey)'>
										<div
											className='can-toggle__switch'
											data-checked='SAFE'
											data-unchecked='XXX'
										/>
									</label>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='filter filterLabel-input'>Title:</div>
							<div className='filter filterInput'>
								<input
									type='text'
									onChange={() => this.handleTitleFilter()}
									id='filterFindTitle'
									placeholder='ex. Rocky (~ for fuzzy ~Rocky)'
								/>
							</div>
						</div>
						<div className='row'>
							<div className='filter filterLabel-input'>Year:</div>
							<div className='filter filterInput'>
								<input
									type='number'
									onChange={() => this.handleYearFilter()}
									id='filterFindYear'
									placeholder='ex. 1976'
								/>
							</div>
						</div>
						<div className='row'>
							<div className='filter filterLabel-input'>Quality:</div>
							<div className='filter filter-quality'>
								<input
									type='radio'
									className='quality-button'
									name='qualitySelector'
									value='sd'
									id='qualitysd'
									onChange={() => this.handleResFilter('sd')}
								/>
								<label htmlFor='qualitysd'>SD</label>
								<input
									type='radio'
									className='quality-button'
									name='qualitySelector'
									value='720'
									id='quality720'
									onChange={() => this.handleResFilter('720')}
								/>
								<label htmlFor='quality720'>720p</label>
								<input
									type='radio'
									className='quality-button'
									name='qualitySelector'
									value='1080'
									id='quality1080'
									onChange={() => this.handleResFilter('1080')}
								/>
								<label htmlFor='quality1080'>1080p</label>
								<input
									type='radio'
									className='quality-button'
									name='qualitySelector'
									value='4k'
									id='quality4k'
									onChange={() => this.handleResFilter('4k')}
								/>
								<label htmlFor='quality4k'>4K</label>
								<input
									type='radio'
									className='quality-button'
									name='qualitySelector'
									value='8k'
									id='quality8k'
									onChange={() => this.handleResFilter('8k')}
								/>
								<label htmlFor='quality8k'>8K</label>
							</div>
						</div>
					</div>
					<i />
				</div>
			</div>
		);
	}
}

export default withContext(VideoFilter);
