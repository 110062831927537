import React, { Component } from 'react';
import axios from 'axios';
import { withContext } from '../../../Persistant/AppContext';
import { getDuration, closeItemOverlay, uuidv4 } from './../../../App.Events';
import VideoPlayer from '../ListingHelpers/tpl_video_player';

axios.defaults.withCredentials = true

class VideoInfo extends Component {
	constructor(props) {
		super(props);
		this.debug = true;
		this.state = this.getInitialState();
		this.sourceCnt = 0;
		this.getRottenTomato();
	}

	getInitialState = () => {
		const initialState = {
			apiEndpoint: '/api',
			rottenBase: 'https://www.rottentomatoes.com',
			rt_video_id: null,
			rt_url: null,
			rt_cast: {
				cast: null,
				creators: null,
				directors: null,
				producers: null,
				screenwriters: null
			},
			rt_genre: null,
			rt_inTheatres: null,
			rt_mainTrailer: {
				hlsURL: null,
				mp4URL: null,
				thumbURL: null
			},
			rt_mpaaRating: null,
			rt_officialURL: null,
			rt_photos: null,
			rt_posters: {
				detailed: null,
				original: null,
				profile: null,
				thumbnail: null
			},
			rt_ratingsSummary: {
				allCritics: {
					averageRating: null,
					hasReviews: null,
					hasScore: null,
					meterClass: null,
					meterValue: null,
					numFresh: null,
					numReviews: null,
					numRotten: null
				},
				allCount: null,
				audience: {
					averageScore: null,
					meterScore: null,
					numTotal: null,
					ratingType: null
				},
				consensus: null,
				freshCount: null,
				rottenCount: null,
				topCritics: {
					averageRating: null,
					hasReviews: null,
					hasScore: null,
					meterClass: null,
					meterValue: null,
					numFresh: null,
					numReviews: null,
					numRotten: null
				}
			},
			rt_ratings: {
				audience_rating: null,
				audience_score: null,
				critics_rating: null,
				critics_score: null,
				dvdReleaseDate: null,
				theaterReleaseDate: null
			},
			rt_reviews: null,
			rt_runningTime: null,
			rt_runningTimeStr: null,
			rt_studio: null,
			rt_synopsis: null,
			rt_title: null,
			rt_videoClips: {
				mainTrailer: null,
				videoClips: null
			},
			rt_year: null
		};
		return initialState;
	};

	getRottenTomato = async () => {
		if (this.props.debug) console.log('SourceData:', this.props.sources[this.sourceCnt]);
		let rt_api_query = `"${this.props.sources[this.sourceCnt].vid_title}"`;
		let movie_year = this.props.sources[this.sourceCnt].vid_year;

		let cors_url = `${this.state.apiEndpoint}/rottentomatoes/${rt_api_query}/${movie_year}`;
		let rt_data
		try {
			rt_data = await axios(cors_url);
			if (rt_data.status == 401)
				this.props.unauthed();
		} catch (ex) {
			console.log(ex)
			this.props.unauthed();
		}
		if (this.props.debug) console.log('RT_DATA:', rt_data.data);
		this.setRottenTomato(rt_data.data);
	};

	setRottenTomato(d) {
		if (this.debug) console.log('Passed RT Data', d);
		console.log('D RESULT:', d);
		this.setState(
			{
				rt_video_id: d.video_id,
				rt_url: d.url,
				rt_cast: {
					cast: d.casts.castItems,
					creators: d.casts.creators,
					directors: d.casts.directors,
					producers: d.casts.producers,
					screenwriters: d.casts.screenwriters
				},
				rt_genre: d.genreSet,
				rt_inTheatres: d.isInTheaters,
				rt_mainTrailer: {
					hlsURL: d.mainTrailer.hlsUrl,
					mp4URL: d.mainTrailer.mp4Url,
					thumbURL: d.mainTrailer.thumbUrl
				},
				rt_mpaaRating: d.mpaaRating,
				rt_officialURL: d.officialUrl,
				rt_photos: d.photos,
				rt_posters: {
					detailed: d.posters.detailed,
					original: d.posters.original,
					profile: d.posters.profile,
					thumbnail: d.posters.thumbnail
				},
				rt_ratingsSummary: {
					allCritics: {
						averageRating: d.ratingSummary.averageRating,
						hasReviews: d.ratingSummary.hasReviews,
						hasScore: d.ratingSummary.hasScore,
						meterClass: d.ratingSummary.meterClass,
						meterValue: d.ratingSummary.meterValue,
						numFresh: d.ratingSummary.numFresh,
						numReviews: d.ratingSummary.numReviews,
						numRotten: d.ratingSummary.numRotten
					},
					allCount: d.ratingSummary.allCount,
					audience: {
						averageScore: d.ratingSummary.audience.averageScore,
						meterScore: d.ratingSummary.audience.meterScore,
						numTotal: d.ratingSummary.audience.numTotal,
						ratingType: d.ratingSummary.audience.ratingType
					},
					consensus: d.ratingSummary.consensus,
					freshCount: d.ratingSummary.freshCount,
					rottenCount: d.ratingSummary.rottenCount,
					topCritics: {
						averageRating: d.ratingSummary.topCritics.averageRating,
						hasReviews: d.ratingSummary.topCritics.hasReviews,
						hasScore: d.ratingSummary.topCritics.hasScore,
						meterClass: d.ratingSummary.topCritics.meterClass,
						meterValue: d.ratingSummary.topCritics.meterValue,
						numFresh: d.ratingSummary.topCritics.numFresh,
						numReviews: d.ratingSummary.topCritics.numReviews,
						numRotten: d.ratingSummary.topCritics.numRotten
					}
				},
				rt_ratings: {
					audience_rating: d.ratings.audience_rating,
					audience_score: d.ratings.audience_score,
					critics_rating: d.ratings.critics_rating,
					critics_score: d.ratings.critics_score,
					dvdReleaseDate: d.ratings.dvdReleaseDate,
					theaterReleaseDate: d.ratings.theaterReleaseDate
				},
				rt_reviews: d.reviews.reviews,
				rt_runningTime: d.runningTime,
				rt_runningTimeStr: d.runningTimeStr,
				rt_studio: d.studio,
				rt_synopsis: d.synopsis,
				rt_title: d.title,
				rt_videoClips: {
					mainTrailer: d.videoClips.mainTrailer,
					videoClips: d.videoClips.videoClips
				},
				rt_year: d.year
			},
			() => {
				if (this.debug) console.log('Current State', this.state);
			}
		);
	}
	getSplatRating(val, t) {
		let result = null;
		switch (t) {
			case 'rating':
				val < 60 ? (result = 'rotten') : (result = 'fresh');
			case 'score':
				val < 60 ? (result = 'popcorn-upright') : (result = 'popcorn-rotten');
		}
		return result;
	}
	formatDate(d) {
		return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
	}
	generateTxt() {
		const element = document.createElement('a');
		const file = new Blob([ JSON.stringify(this.props.vidItem, null, 5) ], { type: 'text/json' });
		element.href = URL.createObjectURL(file);
		element.download =
			this.props.vidItem._id + '-' + this.props.vidItem.server_id + '-' + this.props.vidItem.library_id + '.json';
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
		document.body.removeChild(element);
	}
	handleCloseInfo() {
		closeItemOverlay();
	}
	handlePlayNewItem(sourceNum) {
		//Build callback Data for parent
		let vidFileLocation =
			this.props.sources[sourceNum].library.resource.Connection[0].uri +
			this.props.sources[sourceNum].part_key +
			'?X-Plex-Token=' +
			this.props.sources[sourceNum].library.resource.accessToken +
			'&download=1';
		let artURL =
			this.props.sources[sourceNum].library.resource.Connection[0].uri +
			'/photo/:/transcode?width=1904&height=494&minSize=1&opacity=30&background=36383b&url=' +
			encodeURI(
				this.props.sources[sourceNum].vid_thumb +
					'&X-Plex-Token=' +
					this.props.sources[sourceNum].library.resource.accessToken
			) +
			'&X-Plex-Token=' +
			this.props.sources[sourceNum].library.resource.accessToken;
		artURL = artURL.replace('thumb', 'art');
		console.log(this.props.sources[sourceNum]);
		let callbackData = {
			newTitle: this.props.sources[sourceNum].vid_title,
			oldTitle: this.props.sources[sourceNum].vid_title,
			vidLocation: vidFileLocation,
			artUrl: artURL,
			menuUUID: uuidv4()
		};
		// Pass data of this item back to the MovieListing Parent
		this.props.parentCallback(callbackData);
	}

	// Renderers
	renderTrailer() {
		return this.state.rt_video_id ? (
			<div className="trailer-iframe">
				<iframe
					src={`https://player.theplatform.com/p/HNK2IC/vPUdRgYktZD4/select/media/guid/2474312077/${this.state
						.rt_video_id}?autoPlay=false&fwsitesection=rotten_tomatoes_video_vod#playerurl=https%3A//www.rottentomatoes.com${this
						.state.rt_url}`}
				/>
			</div>
		) : (
			<div />
		);
	}

	renderSourceItem(itm, i, a) {
		this.sourceCnt = i;
		return (
			<div className="sourceRow" key={uuidv4()}>
				<div className="sourceNum col-1">{i}</div>
				<div className="sourceActions col-1 hidden">
					<i onClick={() => this.handlePlayNewItem(i)} className="fa fa-play" />
				</div>
				<div className="sourceName col-3">{itm.library.resource.name}</div>
				<div className="soueceServerId col-1">{itm.library.resource.id}</div>
				<div className="sourceIP col-2">{itm.library.resource.Connection[0].address}</div>
				<div className="sourceToken col-2">
					<a href={`/token/${itm.library.resource.accessToken}`}>{itm.library.resource.accessToken}</a>
				</div>
				<div className="sourceLibrary col-3">{itm.library.lib_title}</div>
				<div className="sourceLibrayId col-1">{itm.library.id}</div>
			</div>
		);
	}
	renderRottenRatingsData() {
		let theater_epoch = '21600';
		let dvd_epoch = '21600';
		if (
			this.state.rt_ratings.theaterReleaseDate !== null ||
			this.state.rt_ratings.theaterReleaseDate !== undefined ||
			this.state.rt_ratings.theaterReleaseDate !== ''
		) {
			theater_epoch = new Date(this.state.rt_ratings.theaterReleaseDate);
		}
		if (
			this.state.rt_ratings.dvdReleaseDate !== null ||
			this.state.rt_ratings.dvdReleaseDate !== undefined ||
			this.state.rt_ratings.dvdReleaseDate !== ''
		) {
			dvd_epoch = new Date(this.state.rt_ratings.dvdReleaseDate);
		}

		return (
			<div className="rt-ratings">
				<div className="rt-ratings-container">
					<div className="`rt-critic rt-score`">
						<div
							className={`icon small ${this.state.rt_ratings.critics_rating.toLowerCase()}`}
							title={this.getRatingsHelper(this.state.rt_ratings.critics_score, 'Critic')}
						/>
						<div className="rating-itm">{this.state.rt_ratings.critics_score}</div>
					</div>
					<div className="`rt-audience rt-score`">
						<div
							className={`icon small popcorn-${this.state.rt_ratings.audience_rating.toLowerCase()}`}
							title={this.getRatingsHelper(this.state.rt_ratings.audience_score, 'Audience')}
						/>
						<div className="rating-itm">{this.state.rt_ratings.audience_score}</div>
					</div>
				</div>
				<div className="rt-release-container">
					<div className="`rt-release rt-theater-release`">
						Theater Release:&nbsp;&nbsp;
						{theater_epoch.getMonth() !== 'NaN' ? (
							`${theater_epoch.getMonth() + 1}-${theater_epoch.getDate()}-${theater_epoch.getFullYear()}`
						) : (
							`Not Available`
						)}
					</div>
					<div className="`rt-release rt-dvd-release`">
						DVD Release:&nbsp;&nbsp;
						{dvd_epoch.getMonth() !== 'NaN' ? (
							`${dvd_epoch.getMonth() + 1}-${dvd_epoch.getDate()}-${dvd_epoch.getFullYear()}`
						) : (
							`Not Available`
						)}
					</div>
				</div>
			</div>
		);
	}
	getRatingsHelper(val, t = null) {
		if (val > 75 && t === 'Critic') {
			return `${t} Reviews > 75% [Certified Fresh]`;
		} else if (val >= 60) {
			return `${t} Ratings >= 60% [Fresh]`;
		} else if (val < 60) {
			return `${t} Ratings < 60% [Rotten]`;
		}
	}
	renderRottenCast = () => {
		return (
			<div className="casting-container">
				{this.state.rt_cast.cast !== null ? (
					<div className="casting-actors">
						<div className="casting-title">Actors</div>
						<div className="cast">
							{this.state.rt_cast.cast.map((item) => this.renderRottenCastItem(item, 'actor'))}
						</div>
					</div>
				) : (
					<div />
				)}
				{this.state.rt_cast.creators !== null ? (
					<div className="casting-creators">
						<div className="casting-title">Creators</div>
						<div className="cast">
							{this.state.rt_cast.creators.map((item) => this.renderRottenCastItem(item, 'creator'))}
						</div>
					</div>
				) : (
					<div />
				)}
				{this.state.rt_cast.directors !== null ? (
					<div className="casting-directors">
						<div className="casting-title">Directors</div>
						<div className="cast">
							{this.state.rt_cast.directors.map((item) => this.renderRottenCastItem(item, 'director'))}
						</div>
					</div>
				) : (
					<div />
				)}
				{this.state.rt_cast.producers !== null ? (
					<div className="casting-producers">
						<div className="casting-title">Producers</div>
						<div className="cast">
							{this.state.rt_cast.producers.map((item) => this.renderRottenCastItem(item, 'producer'))}
						</div>
					</div>
				) : (
					<div />
				)}
				{this.state.rt_cast.screenwriters !== null ? (
					<div className="casting-screenwriters">
						<div className="casting-title">Screenwriters</div>
						<div className="cast">
							{this.state.rt_cast.screenwriters.map((item) =>
								this.renderRottenCastItem(item, 'screenwriter')
							)}
						</div>
					</div>
				) : (
					<div />
				)}
			</div>
		);
	};
	renderRottenCastItem = (itm, sw) => {
		switch (sw) {
			case 'actor':
				return (
					<div className="cast-container" key={uuidv4()}>
						<div className="cast-img">
							<a
								href={`https://www.rottentomatoes.com${itm.person.url}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={itm.person.thumbnailImg} alt={`cast-img-${itm.person.name}`} />
							</a>
						</div>
						<div className="cast-title">
							<a
								href={`https://www.rottentomatoes.com${itm.person.url}`}
								target="_blank"
								rel={`noopener noreferrer`}
							>
								{itm.person.name}
							</a>
							<br />
							<div className="cast-character">
								{itm.characters !== undefined ? (
									itm.characters.map((character, i, a) => {
										let result = null;
										i + 1 === a.length
											? (result = `${character.name}`)
											: (result = `${character.name},`);
										return result;
									})
								) : null}
							</div>
						</div>
					</div>
				);
			default:
				return (
					<div className="cast-container">
						<div className="cast-img">
							<a
								href={`https://www.rottentomatoes.com${itm.url}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={itm.thumbnailImg} alt={`cast-img-${itm.name}`} />
							</a>
						</div>
						<div className="cast-title">
							<a
								href={`https://www.rottentomatoes.com${itm.url}`}
								target="_blank"
								rel={`noopener noreferrer`}
							>
								{itm.name}
							</a>
						</div>
					</div>
				);
		}
	};
	renderRottenReviews = (itm) => {
		if (this.props.debug) console.log('RenderRottenReview Itm', itm);
		try {
			let freshness = itm.isFresh ? 'fresh' : 'rotten';
			return (
				<div className="rotten-review-item" key={uuidv4()}>
					<div
						className="rotten-review-reviewer"
						style={{ backgroundImage: `url(${itm.critic.thumbnailImage})` }}
					>
						<a href={`${this.state.rottenBase}${itm.critic.url}`} target="_blank" rel="noopener noreferrer">
							<span className="reviewer-name">{itm.critic.name}</span>
						</a>
						<br />
						<span className="reviewer-publisher">{itm.publication.name}</span>
					</div>
					<div className="rotten-review-content">
						<div className={`icon small ${freshness}`} />
						<div className="rt-quote">{itm.quote}</div>
						<a href={itm.links.review} className="full-review" target="_blank" rel="noopener noreferrer">
							...full review
						</a>
					</div>
				</div>
			);
		} catch (error) {
			if (this.props.debug) {
				console.log('RenderReviews Error:', itm);
				console.log('RenderReviews Error:', error);
			}
		}
	};

	render() {
		return (
			<div className="right" key={uuidv4()}>
				<div className="info-head" />
				<div className="text-content">
					<div className="info-instructions">
						(Press ESC to leave this screen)
						<i className="fa fa-times-circle" onClick={() => this.handleCloseInfo()} />
					</div>
					<div className="info-container">
						<div className="info-poster">
							<img
								src={
									this.props.sources[0].library.resource.Connection[0].uri +
									'/photo/:/transcode?width=295&height=166&minSize=295&url=' +
									encodeURI(
										this.props.sources[0].vid_thumb +
											'&X-Plex-Token=' +
											this.props.sources[0].library.resource.accessToken
									) +
									'&X-Plex-Token=' +
									this.props.sources[0].library.resource.accessToken
								}
								alt="movie poster"
							/>
						</div>
						<div className="info-details">
							<div className="info-details-title">{this.props.sources[0].vid_title}</div>
							<div className="info-details-year">{this.props.sources[0].vid_year}</div>
							<div className="info-details-dblcontainer">
								<div className="info-details-runtime">
									{getDuration(this.props.sources[0].vid_duration, true)}
								</div>
								<div className="info-details-rating">
									{this.state.rt_mpaaRating !== null ? (
										this.state.rt_mpaaRating
									) : this.props.sources[0].vid_content_rating ? (
										<p>
											<span className="spanTitle">Rating:</span>
											<span className="spanRating">
												{this.props.sources[0].vid_content_rating}
											</span>
										</p>
									) : (
										<div />
									)}
								</div>
							</div>
							<div className="info-seperator" />
							<div className="info-rotten-tomato">
								{this.state.rt_ratings.audience_rating &&
								this.state.rt_ratings.audience_score &&
								this.state.rt_ratings.critics_rating &&
								this.state.rt_ratings.critics_score ? (
									this.renderRottenRatingsData()
								) : (
									<div />
								)}
							</div>
							<div className="info-details-video">
								<span className="spanTitle">Video:</span> {this.props.sources[0].vid_resolution} ({this.props.sources[0].vid_videoCodec})
							</div>
							<div className="info-details-audio">
								<span className="spanTitle">Audio:</span> ({this.props.sources[0].vid_audioCodec})
							</div>
							<div className="info-seperator" />
							<div className="info-details-summary">
								{this.state.rt_synopsis !== null ? (
									this.state.rt_synopsis
								) : (
									this.props.sources[0].vid_summary
								)}
							</div>
						</div>
					</div>

					<div className="info-rotten-trailer">
						<div className="review-title">Trailer</div>
						<div className="trailer-container">
							{this.state.rt_video_id ? this.renderTrailer() : <div />}
						</div>
					</div>

					<div className="info-rotten-cast">
						{this.state.rt_cast != null ? this.renderRottenCast() : <div />}
					</div>

					<div className="info-rotten-reviews">
						<div className="review-container">
							<div className="review-title">Reviews</div>
							{this.state.rt_reviews != null ? (
								this.state.rt_reviews.map((item) => this.renderRottenReviews(item))
							) : (
								<div />
							)}
						</div>
					</div>
					<div className="sources-container">
						<div className="sourceRow">
							<div className="sourceTitle col-1">#</div>
							<div className="sourceTitle col-1 hidden">Geo</div>
							<div className="sourceTitle col-3">Server Name</div>
							<div className="sourceTitle col-1">Server ID</div>
							<div className="sourceTitle col-2">Server IP</div>
							<div className="sourceTitle col-2">AccessToken</div>
							<div className="sourceTitle col-3">Library Name</div>
							<div className="sourceTitle col-1">Library ID</div>
						</div>
						<div className="info-seperator" />
						{this.props.sources.map((item, i, a) => this.renderSourceItem(item, i, a))}
					</div>
				</div>
			</div>
		);
	}
}

export default withContext(VideoInfo);
