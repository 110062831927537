import React, { Component } from 'react';
import MovieListings from '../Components/MovieListings/MovieListings';

class ShowMovies extends Component {
	render() {
		return (
			<div onScroll={() => console.log('scrolling')}>
				<MovieListings />
			</div>
		);
	}
}

export default ShowMovies;
