import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppContextProvider } from './Persistant/AppContext';
import App from './plexDataHook';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from 'react-apollo';
import client from './apollo';

ReactDom.render(
    <ApolloProvider client={client}>
        <Router>
            <AppContextProvider>
                <App />
            </AppContextProvider>
        </Router>
    </ApolloProvider>
    , document.getElementById('root')
);

serviceWorker.unregister();
