import React from 'react';
import ReactDom from 'react-dom';

// ############################################
// Custom Event Listeners
export function CreateListeners() {
	document.addEventListener('closeSmOverlay', handleHideSmOverlay, false);
	document.addEventListener('showSmOverlay', handleShowSmOverlay, false);
	document.addEventListener('keyup', handleKeyPresses, false);
}

// ############################################
// Constants
const animations = {
	show: 'fadeInUp',
	hide: 'fadeOutDown',
	allowNext: true
};
// ############################################
// ContextMenu Stuff
let origin = {
	left: 0,
	top: 0
};
let menuVisible = false;

const toggleContextMenu = (command) => {
	const plexwebContextMenu = document.querySelector('.plexwebContextMenu');
	plexwebContextMenu.style.display = command === 'showContextMenu' ? 'block' : 'none';
	plexwebContextMenu.style.visibility = command === 'showContextMenu' ? 'visible' : 'hidden';
	menuVisible = !menuVisible;
};
const setPosition = ({ top, left }) => {
	const plexwebContextMenu = document.querySelector('.plexwebContextMenu');
	plexwebContextMenu.style.left = `${left}px`;
	plexwebContextMenu.style.top = `${top}px`;
	toggleContextMenu('showContextMenu');
};
function handleContext(e) {
	e.preventDefault();
	origin.left = e.target.offsetLeft + 40;
	origin.top = e.target.offsetTop + 40;
	setPosition(origin);
}
document.addEventListener('scroll', function(e) {
	console.log('scrolled');
});
document.addEventListener('contextmenu', function(e) {
	menuVisible = false;
	if (e.target.classList.contains('vid_poster_img')) handleContext(e);
});
document.addEventListener('click', function(e) {
	const plexwebContextMenu = document.querySelector('.plexwebContextMenu');
	const hideMenu = () => {
		plexwebContextMenu
			? (plexwebContextMenu.style.visibility = 'hidden')
			: console.log('PlexWebContextmenu not Found (probably login screen)');
	};
	menuVisible = true ? hideMenu() : null;
});
document.addEventListener('saveSettings', function() {
	console.log('saveSettings Fired');
	let ariaSettings;
	localStorage.getItem('aria-settings')
		? (ariaSettings = JSON.parse(localStorage.getItem('aria-settings')))
		: (ariaSettings = {});
	ariaSettings.secret = document.querySelector('#settings-aria-secret').value;
	ariaSettings.uri = document.querySelector('#settings-aria-uri').value;
	localStorage.setItem('aria-settings', JSON.stringify(ariaSettings));

	ReactDom.render(
		<div id="hover-title" className="hover-title">
			<div className="quick-info-container">
				<div className="quick-info-close">
					<i className="fa fa-times-circle close-quick-info" onClick={() => fireEvent('closeSmOverlay')} />
				</div>
				<div className="quick-info-header">SETTINGS SAVED!</div>
			</div>
		</div>,
		document.querySelector('#sm-overlay')
	);
	fireEvent('showSmOverlay');
	setTimeout(() => {
		fireEvent('closeSmOverlay');
	}, 5000);
});

// ############################################
// Hotkey Handler
function handleKeyPresses(e) {
	switch (e.keyCode) {
		case 27: // ESC
			closeItemOverlay();
			break;
		case 113: // F2
			console.log('ToggleNSFW');
			var nsfw_filter = document.querySelector('[id=d]');
			nsfw_filter.checked = !nsfw_filter.checked;
			let user = JSON.parse(localStorage.getItem('user'));
			if (user == null)
			  user = {};
			user.safeSearch = nsfw_filter.checked;
			window.localStorage.setItem('user', JSON.stringify(user));
			var nsfw_itms = document.querySelectorAll('.nsfw');
			for (var i = 0; i < nsfw_itms.length; i++) {
				nsfw_filter.checked ? nsfw_itms[i].classList.add('hidden') : nsfw_itms[i].classList.remove('hidden');
			}

			let searchbar = document.querySelector('input.searchbar');
			if (user.safeSearch ) {
				searchbar.classList.remove('searchbar-nsfw');
				searchbar.setAttribute('placeholder', 'Movie Search');
			} else {
				searchbar.classList.add('searchbar-nsfw');
				searchbar.setAttribute('placeholder', 'Movie Search [NS]');
			}
			break;
		default:
			break;
	}
}

// ############################################
// Internal Functions
function _setAnimation(ele, newAni, oldAni) {
	const node = document.querySelector(ele);
	node.removeAttribute('hidden');
	node.setAttribute('style', '{animation-delay: .1s;}');
	node.classList.remove('animated', oldAni);
	node.classList.add('animated', newAni);
}

// ############################################
// SMALL OVERLAY (Hover Overlay) EVENTS
function handleShowSmOverlay() {
	_setAnimation('#sm-overlay', animations.show, animations.hide);
	animations.allowNext = false;
}

function handleHideSmOverlay() {
	_setAnimation('#sm-overlay', animations.hide, animations.show);
}

// ############################################
// External (Export) Functions (Available able to be called from other components
export function closeItemOverlay() {
	var closeItemOverlay = new CustomEvent('closeItemOverlay', {});
	document.dispatchEvent(closeItemOverlay);
}

export function getDuration(duration, ext = null) {
	var seconds = parseInt((duration / 1000) % 60),
		minutes = parseInt((duration / (1000 * 60)) % 60),
		hours = parseInt((duration / (1000 * 60 * 60)) % 24);
	//, milliseconds = parseInt((duration%1000)/100);

	hours = hours < 10 ? '0' + hours : hours;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	seconds = seconds < 10 ? '0' + seconds : seconds;

	if (ext === null) {
		return hours + ':' + minutes + ':' + seconds;
	} else {
		return hours + 'hr ' + minutes + 'min ' + seconds + 'sec';
	}
}

export function getSize(size) {
	var bytes = size;
	const sizes = [ 'Bytes', 'KB', 'MB', 'GB', 'TB' ];
	if (bytes === 0) return 'n/a';
	const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
	if (i === 0) return `${bytes} ${sizes[i]})`;
	return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r && 0x3) || 0x8;
		return v.toString(16);
	});
}

export function downloadFile(file) {
	window.open(file);
}

export function fireEvent(ev, e = null) {
	var elem = document;
	var event = new Event(ev, { params: e });
	elem.dispatchEvent(event);
}

export function vidInfoReport(obj) {
	return JSON.stringify(obj, null, 5);
}

export function getFileNameFromPath(value) {
	//const rePattern = /([^\/\\]+\.\w+)$/
	const rePattern = /([^/\\]+\.\w+)$/;
	const reFile = new RegExp(rePattern);
	const reResult = reFile.exec(value);
	return reResult;
}

export function extractMediaTitle(path) {
	var filename = getFileNameFromPath(path);
	var regex = /^(.+?)[.( \t]*(?:(19\d{2}|20(?:0\d|1[0-9])).*|(?:(?=bluray|\d+p|brrip|webrip)..*)?[.](mkv|avi|mpe?g|mp4)$)/i;
	var m, out;

	if (filename !== null) {
		filename = filename[0];

		if (m === regex.exec(filename)) {
			//const vTitle = _titlelize(m[1]);
			const vTitle = m[1];
			const vYear = m[2];
			out = { title: vTitle, year: vYear };
		} else {
			out = null;
		}
	}
	return out;
}
