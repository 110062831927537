import React, { Component } from 'react';
import { withContext } from './../../../Persistant/AppContext';
import noPoster from '../../../Assets/Images/no-poster-sq.jpg';
import TVEpisodes from '../ListingHelpers/tpl_tv_episodes-new';
import { VideoQuickInfo } from './tpl_video_quick_info';
import { fireEvent } from '../../../App.Events';

class TVPoster extends Component {

	constructor(props) {
		super(props);
		this.state = {
			firstload: true,
			server: this.props.fileDetails.first_server,
			thumb: this.props.fileDetails.first_thumb
		};
	}

	handleImgError(e) {
		// Image Error Load Handler
		// If fails on first server, tries second server
		// If fails second server displays generic No Poster Image		
		if (this.state.firstload){
			//console.log('Img Err - First Load');
			
			this.setState({
				firstload: false,
				server: this.props.fileDetails.last_server,
				thumb: this.props.fileDetails.last_thumb
			});
			e.target.src = `http://${this.state.server.s_address}:${
				this.state.server.s_port
				}/photo/:/transcode?width=286&height=161&minSize=1&upscale=1&url=${encodeURI(
				this.state.thumb + '&X-Plex-Token=' +
				this.state.server.s_accessToken,
				)}&X-Plex-Token=${this.state.server.s_accessToken}`;
		}else{
			e.target.src = noPoster;
		}		
	}

	getEpPoster() {
		return `http://${this.state.server.s_address}:${
			this.state.s_port
			}/photo/:/transcode?width=405&height=228&minSize=1&upscale=1&url=${encodeURI(
			this.state.thumb + '&X-Plex-Token=' +
			this.state.server.s_accessToken,
			)}&X-Plex-Token=${this.state.server.s_accessToken}`;
	}

	getSeriesArt(){
		return `http://${this.state.server.s_address}:${
			this.state.server.s_port
		}${this.state.thumb.replace('thumb', 'art') +
				'&X-Plex-Token=' +
				this.state.server.s_accessToken}`
	}

	handleShowInfo() {
		var showItemOverlay = new CustomEvent('showItemOverlay', {
			detail: { content: this.generateOverlay(), bgImg: this.getSeriesArt() },
		});
		document.dispatchEvent(showItemOverlay);
	}

	generateOverlay() {
		//console.log('props', this.props)
		let bg_settings = {
			width: 640,
			height: 480,
			blur: 10,
			opacity: 30
		}
		return (
				<TVEpisodes 
					showTitle={this.props.showTitle}
					showPoster={`http://${this.state.server.s_address}:${
						this.state.server.s_port
						}/photo/:/transcode?width=556&height=692&minSize=1&upscale=1&url=${encodeURI(
							this.state.thumb.replace('thumb', 'art') +
							'&X-Plex-Token=' +
							this.state.server.s_accessToken,
						)}&X-Plex-Token=${this.state.server.s_accessToken}`}
					vidArt={`http://${this.state.server.s_address}:${
						this.state.server.s_port
						}${this.state.thumb.replace('thumb', 'art') +
							'?X-Plex-Token=' +
							this.state.server.s_accessToken}`}
					showSummary={this.props.showSummary}
					showStudio={this.props.showStudio}
					showYear={this.props.showYear}
					showRating={this.props.showRating}
				/>
			);
	}

	getNSFWSetting() {
		const prefix = `tv-ep-itm lib-${this.props.vidItems[0].library._id}`;
		if (this.props.isNSFW) {
			if (!this.user || this.user.safeSearch) {
				return prefix + ' nsfw hidden';
			} else {
				return prefix + ' nsfw';
			}
		} else {
			return prefix;
		}
	}	

	render() {
		//console.log('TV Poster Render', this.props);
		return (
			<div
				className="tv-ep-itm"
				vidYear={this.props.showYear ? this.props.showYear : `N/A`}
				vidTitle={this.props.showTitle}
			>
				<div className="vid-header">
					<div className="vid-geo">
						<span className="spanTitle">
							{`${this.props.count} count`}
						</span>
					</div>
					<div className="vid-report">
						<i
							className="fa fa-exclaimation-triangle"
							title={`Report Library ID: [N/A]`}
							libid={`N/A`}
							onClick={``}
						/>
					</div>
				</div>
				<img
					src={this.getEpPoster()}
					className='responsive'
					alt={`${this.props.showTitle} poster`}
					onError={this.handleImgError.bind(this)}
					onClick={() => this.handleShowInfo()}
				/>
				<div className="vid-title ellipsis" onClick={() => this.showHoverTitle()}>
					<span title={this.props.showTitle}>{this.props.showTitle}</span>
				</div>
				<div className="vid-extras">
					<div className="vid-extras-content-left">
						Year: <span className="vid-year">{
							this.props.showYear ? this.props.showYear : `N/A`						
						}</span>
					</div>
					<div className="vid-extras-content-right">
						<span className="vid-count" title={`${this.props.relevancy}% relevancy`}>{this.props.relevancy}% rel</span> 
					</div>
				</div>
			</div>
		);
	}
}

export default withContext(TVPoster);
